import { colors } from "../../colors";
import { predefinedAnimations } from "../../animations";

export const linkStyles = {
  root: {
    fontWeight: 600,
    color: colors.secondary.main,
    transition: `all ${predefinedAnimations.default}`,
    textDecoration: "none",

    "&:hover": {
      textDecoration: "none",
      color: colors.secondary.dark,
    },
  },
};
