import { createClient } from "contentful-management";

import { appConfig } from "app-config";

/**
 * Contentful Management API Client. Contains methods which allow access to any operations
 * that can be performed with a management token.
 *
 * Contains methods to fetch content, assets, etc.
 *
 * {@link ContentfulClientApi}
 */
export const getManagementApiClient = () => {
  if (!appConfig?.contentful?.managementAccessToken) {
    return;
  }

  return createClient({
    accessToken: appConfig.contentful.managementAccessToken,
  });
};
