import { makeStyles } from "@material-ui/core";

import { Shadow, shadows } from "ui/theme/material/shadows";
import { CustomTheme } from "ui/theme/material/theme";

export const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: theme.palette.common.white,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    color: theme.palette.primary.main,
    boxShadow: shadows[Shadow.Short],
  },

  title: {
    "&:hover": {
      textDecoration: "none",
    },
  },

  logo: {
    height: "32px",
  },

  menuButton: {
    borderRadius: theme.config.borderRadius,
    padding: theme.spacing(2),
  },
}));
