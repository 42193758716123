import cookies from "js-cookie";
import qs from "qs";

import { appConfig } from "app-config";
import { getSnakeCase } from "lib/get-snake-case";

import { AuthToken, UserRole } from "./types";
import { azureResponseType } from "./shared/constants/auth";

const { clientId, tenantId } = appConfig;

export const setToken = (name: AuthToken, token: string, expires: number) => {
  const expiresInMinutes = Number(expires ?? 0);
  const expiresInMs = expiresInMinutes * 1000;
  const expiresInHour = new Date(new Date().getTime() + expiresInMs);

  if (name === AuthToken.Access) {
    cookies.set(name, token, { expires: expiresInHour });
  } else {
    localStorage.setItem(name, token);
  }
};

export const logout = () => {
  cookies.remove(AuthToken.Access);
  localStorage.removeItem(AuthToken.Affiliate);
  localStorage.removeItem(AuthToken.Product);
  localStorage.removeItem(AuthToken.Enrollment);
  localStorage.removeItem(AuthToken.EmployerToken);
  localStorage.removeItem(AuthToken.Member);
  localStorage.removeItem(AuthToken.AcbToken);
};

export const getToken = (tokenKey: AuthToken = AuthToken.Access) => {
  if (cookies.get(AuthToken.Access)) {
    return cookies.get(tokenKey) ?? localStorage.getItem(tokenKey);
  }

  return undefined;
};

export const getAuthLink = () => {
  const redirectUri = window.location.origin;
  const scope = `${clientId}/.default`;

  const params = qs.stringify(
    getSnakeCase({
      clientId,
      redirectUri,
      scope,
      responseType: azureResponseType,
    }),
    { addQueryPrefix: true },
  );

  return `${appConfig.authRootLink}/${tenantId}/oauth2/v2.0/authorize${params}`;
};

export const extractUsernameFromJWT = (jwt?: string): string => {
  try {
    const payload = jwt.split(".")[1];
    const encodedPayload = atob(payload);
    const payloadObject = JSON.parse(encodedPayload);

    return payloadObject.name;
  } catch {
    return "";
  }
};

export const checkUserRole = (role: UserRole): boolean => {
  try {
    const jwt = getToken();
    const payload = jwt.split(".")[1];
    const encodedPayload = atob(payload);
    const jwtObject = JSON.parse(encodedPayload);
    const rolesList: string[] = jwtObject.roles;

    if (rolesList && rolesList.length > 0) {
      return rolesList.some((e) => e === role.toString());
    } else {
      return false;
    }
  } catch (e) {
    let errMsg: string;

    if (e instanceof Error) errMsg = e.message;
    else errMsg = String(e);

    console.error("Exception while checking user roles: " + errMsg);

    return false;
  }
};
