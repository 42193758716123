import {
  ContentModelType,
  getDeliveryApiClient,
  IdentifiableContent,
} from "lib/contentful";

import { defaultParameters } from "./default-parameters";
import { unwrapEntryCollection } from "./utils";

const deliveryClientApi = getDeliveryApiClient();

/**
 * Wraps Contentful SDK getEntries method, applying defaultParameters and getting rid of
 * overabundant information such as:
 *   - items
 *   - sys
 *   - fields
 *
 * {@see Entry} {@see Sys} {@see EntryCollection}
 */
export const getEntries = async <T extends IdentifiableContent>(
  contentType: ContentModelType,
  filter: object,
  shouldCache = true,
): Promise<T[]> => {
  const entryCollection = await deliveryClientApi.getEntries<T>({
    ...defaultParameters,
    shouldCache,
    // eslint-disable-next-line @typescript-eslint/camelcase
    content_type: contentType,
    ...filter,
  });

  return unwrapEntryCollection(entryCollection);
};
