import React, { memo } from "react";
import { Typography } from "@material-ui/core";
import clsx from "clsx";

import { useStyles } from "ui/atoms/decorated-typography/decorated-typography.styles";
import { DecoratedTypographyProps } from "ui/atoms/decorated-typography/decorated-typography.types";

export const DecoratedTypography = memo(
  ({
    className,
    variant = "body1",
    children,
    typographyClassName,
    disabled = false,
    ...rest
  }: DecoratedTypographyProps): React.ReactElement => {
    const classes = useStyles({ variant, disabled });

    return (
      <div className={className}>
        <Typography
          {...rest}
          className={clsx(classes.root, typographyClassName)}
          variant={variant}
        >
          {children}
        </Typography>

        <div className={classes.spacer} />
      </div>
    );
  },
);
