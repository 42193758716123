import { createSlice } from "@reduxjs/toolkit";

import { LoadingStatus } from "types/loading-status";

import { getExtendedProductsThunk } from "./actions";
import { extendedProducts } from "./state";

export const extendedProductsSlice = createSlice({
  name: "extendedProducts",
  initialState: extendedProducts,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getExtendedProductsThunk.pending, (state) => {
      state.loadingStatus =
        state.loadingStatus === LoadingStatus.Init
          ? LoadingStatus.InitLoading
          : LoadingStatus.Loading;
    });

    builder.addCase(getExtendedProductsThunk.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loadingStatus = LoadingStatus.Done;
    });

    builder.addCase(getExtendedProductsThunk.rejected, (state) => {
      state.loadingStatus = LoadingStatus.Done;
    });
  },
});
