import { colors } from "../../colors";

export const inputLabelStyles = {
  root: {
    "&$focused": {
      // increase the specificity for the pseudo class
      color: colors.primary.main,
    },
  },
  asterisk: {
    color: colors.error.main,
  },
};
