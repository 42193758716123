import React, {
  ReactElement,
  memo,
  useCallback,
  useState,
  useMemo,
  ReactNode,
} from "react";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined";
import { useFormikContext } from "formik";
import ArrowDropDownRoundedIcon from "@material-ui/icons/ArrowDropDownRounded";
import isNil from "lodash/isNil";

import {
  AutocompleteField,
  AutocompleteFieldProps,
} from "ui/molecules/autocomplete-field";

import { useStyles } from "./editable-autocomplete-field.style";
import { SelectOption } from "../../../types/select-option";

export const EditableAutocompleteField = memo<
  AutocompleteFieldProps<SelectOption<string>> & {
    isClearButtonVisible?: boolean;
    adornment?: ReactNode;
  }
>(
  ({ isClearButtonVisible, adornment, ...props }): ReactElement => {
    const { values, setFieldValue } = useFormikContext<any>();
    const [isDisabled, setIsDisabled] = useState(Boolean(values?.[props.name]));
    const [isOpen, setIsOpen] = useState(false);

    const classes = useStyles({
      hasEmptyValue: !isNil(isClearButtonVisible)
        ? !isClearButtonVisible
        : !values?.[props.name],
      isDisabled: props.disabled,
      isOpen,
    });

    const handleClearButton = useCallback(() => {
      setIsDisabled(false);
      setFieldValue(props.name, "", false);
    }, [props.name, setIsDisabled, setFieldValue]);

    const handleOpenDropdown = useCallback(() => {
      setIsOpen(true);
    }, [setIsOpen]);

    const handleToggleDropdown = useCallback(() => {
      setIsOpen(!isOpen);
    }, [isOpen, setIsOpen]);

    const handleCloseDropdown = useCallback(() => {
      setIsOpen(false);
    }, [setIsOpen]);

    const inputProps = useMemo(() => {
      return { disabled: isDisabled };
    }, [isDisabled]);

    const InputProps = useMemo(() => {
      return {
        endAdornment: (
          <InputAdornment position="end">
            {adornment && adornment}

            <IconButton
              className={classes.clearButton}
              edge="start"
              size="small"
              color="primary"
              onClick={handleClearButton}
            >
              <ClearOutlinedIcon />
            </IconButton>

            <IconButton
              className={classes.arrowButton}
              edge="start"
              size="small"
              onClick={handleToggleDropdown}
            >
              <ArrowDropDownRoundedIcon />
            </IconButton>
          </InputAdornment>
        ),
      };
    }, [handleClearButton, handleToggleDropdown, classes, adornment]);

    const getOptionLabel = useCallback(({ label }: SelectOption<string>) => {
      return label;
    }, []);

    return (
      <AutocompleteField
        {...props}
        open={isOpen}
        className={classes.autocompleteField}
        inputProps={inputProps}
        getOptionLabel={getOptionLabel}
        InputProps={InputProps}
        onOpen={handleOpenDropdown}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        onClose={handleCloseDropdown}
      />
    );
  },
);
