import React, { ReactElement, ReactNode } from "react";
import { Typography } from "@material-ui/core";

import { useStyles } from "./styles";

export const StyledListElement = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const { li } = useStyles({});

  return (
    <Typography variant="body1" component="li" className={li}>
      {children}
    </Typography>
  );
};
