import React, { memo } from "react";
import { Box } from "@material-ui/core";

import { DecoratedTypography } from "ui/atoms/decorated-typography/decorated-typography.component";

import { SectionWrapperProps } from "./types";

export const SectionWrapper = memo(
  ({ title, children }: SectionWrapperProps) => {
    return (
      <Box marginTop={4}>
        <DecoratedTypography variant="subtitle1" color="textSecondary">
          {title}
        </DecoratedTypography>

        <Box marginTop={2}>{children}</Box>
      </Box>
    );
  },
);

SectionWrapper.displayName = "SectionWrapper";
