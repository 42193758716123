import { useEffect } from "react";

const userActivityEvents = [
  "mousemove",
  "mousedown",
  "touchstart",
  "click",
  "keydown",
  "scroll",
];

export const useWatchUserActivity = (handleUserActivity: () => void): void => {
  useEffect(() => {
    userActivityEvents.forEach((eventName) => {
      document.addEventListener(eventName, handleUserActivity, true);
    });

    return (): void => {
      userActivityEvents.forEach((eventName) => {
        document.removeEventListener(eventName, handleUserActivity, true);
      });
    };
  }, [handleUserActivity]);
};
