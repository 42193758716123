import snakeCase from "lodash/snakeCase";
import isObject from "lodash/isObject";

export const getSnakeCase = (original: Record<string, any>) => {
  return Object.keys(original).reduce((acc, key) => {
    const snakeKey = snakeCase(key);
    const value = original[key];
    const isValueObject = isObject(value);

    if (isValueObject) {
      acc[snakeKey] = getSnakeCase(value);
    } else {
      acc[snakeKey] = value;
    }

    return acc;
  }, {} as Record<string, any>);
};
