import { makeStyles } from "@material-ui/core";
import { fade } from "@material-ui/core/styles";

import { PaletteType } from "ui/theme/material/palette";
import { CustomTheme } from "ui/theme/material/theme";
import { opacities } from "ui/theme/opacities";

import { EditableTextStyleProps } from "./editable-text-field.type";

export const useStyles = makeStyles<CustomTheme, EditableTextStyleProps>(
  (theme) => {
    const mobileScreen = theme.breakpoints.down("xs");

    return {
      autocompleteField: {
        "&:hover $clearButton": {
          visibility: ({ hasEmptyValue }) =>
            hasEmptyValue ? "hidden" : "visible",
        },
      },

      clearButton: {
        visibility: "hidden",

        "$autocompleteField:hover &": {
          visibility: "visible",
        },

        [mobileScreen]: {
          visibility: ({ hasEmptyValue }) =>
            hasEmptyValue ? "hidden" : "visible",
        },
      },

      arrowButton: {
        transform: ({ isOpen }) =>
          isOpen ? "rotateZ(180deg)" : "rotateZ(0deg)",
        marginRight: theme.spacing(-1),
        color: ({ isDisabled }) =>
          isDisabled
            ? fade(
                theme.palette[PaletteType.Primary].main,
                opacities.colored.disabled,
              )
            : theme.palette[PaletteType.Primary].main,
      },
    };
  },
);
