import React, { forwardRef, memo } from "react";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import ErrorIcon from "@material-ui/icons/ErrorOutline";
import InfoIcon from "@material-ui/icons/Info";
import WarningIcon from "@material-ui/icons/WarningOutlined";
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
import { VariantType } from "notistack";
import { makeStyles } from "@material-ui/core/styles";

import { CustomTheme } from "../../theme/material/theme";
import { NotificationType } from "../../../types/notification-type";

const useStyles = makeStyles((theme: CustomTheme) => ({
  [NotificationType.Info]: {
    backgroundColor: theme.palette.primary.light,
  },
  [NotificationType.Success]: {
    backgroundColor: theme.palette.success.main,
  },
  [NotificationType.Warning]: {
    backgroundColor: theme.palette.warning.main,
  },
  [NotificationType.Error]: {
    backgroundColor: theme.palette.error.main,
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}));

const variantIcon = {
  [NotificationType.Info]: InfoIcon,
  [NotificationType.Success]: CheckCircleOutline,
  [NotificationType.Warning]: WarningIcon,
  [NotificationType.Error]: ErrorIcon,
};

export interface SnackProps {
  message: string;
  variant: VariantType;
}

export const Snack = memo<SnackProps>(
  forwardRef(
    ({ message, variant }: SnackProps, reference): React.ReactElement => {
      const Icon = variantIcon[variant as keyof typeof variantIcon];
      const classes = useStyles({});

      return (
        <SnackbarContent
          ref={reference}
          className={classes[variant as keyof typeof useStyles]}
          aria-describedby="client-snackbar"
          message={
            <Box id="client-snackbar" display="flex" alignItems="center">
              <Icon className={classes.icon} />

              <Box marginLeft={1}>
                <Typography>{message}</Typography>
              </Box>
            </Box>
          }
        />
      );
    },
  ),
);

Snack.displayName = "Snack";
