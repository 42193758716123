import { AfterResponseHook } from "ky";
import { Http } from "@status/codes/build/http";

import { logout } from "../features/auth";

export const afterResponse: AfterResponseHook = async (
  request,
  options,
  response,
) => {
  if (response.status === Http.Unauthorized) {
    logout();
  }
};
