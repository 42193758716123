import React, { lazy, Suspense, Fragment } from "react";
import { SnackbarProvider } from "notistack";
import CssBaseline from "@material-ui/core/CssBaseline";

import { useAuthContext } from "features/auth";
import { Loader } from "ui";
import { NetworkRequestErrorListener } from "api/error-handler/network-request-error-listener";
import { appConfig } from "app-config";
import { useAdminTimeout } from "core/hooks/use-admin-timeout";
import { useLogoutTimeoutHandler } from "core/hooks/use-logout-timeout-handler";

import { SnackConsumer } from "./lib/snack";

const AuthenticatedApp = lazy(() =>
  import("./core/components/authenticated-app/authenticated-app.component"),
);

const UnauthenticatedApp = lazy(() =>
  import("./core/components/unauthenticated-app/unauthenticated-app.component"),
);

export const App = () => {
  const {
    token,
    affiliateToken,
    productToken,
    isLoadingTokens,
  } = useAuthContext();

  useAdminTimeout();
  useLogoutTimeoutHandler();

  const isAllTokensPresent = token && affiliateToken && productToken;

  return (
    <Suspense fallback={<Loader />}>
      {isLoadingTokens && <Loader />}
      <SnackbarProvider maxSnack={appConfig.maxSnackCount}>
        <Fragment>
          <CssBaseline />

          <SnackConsumer />
          <NetworkRequestErrorListener />

          {isAllTokensPresent ? <AuthenticatedApp /> : <UnauthenticatedApp />}
        </Fragment>
      </SnackbarProvider>
    </Suspense>
  );
};
