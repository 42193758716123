import makeStyles from "@material-ui/styles/makeStyles";

import { getFontError } from "../../theme/utils/get-font-error";
import { theme } from "../../theme/material/theme";
import { liMarginBottom } from "../../theme/layout";

export const useStyles = makeStyles({
  paragraph: {
    marginTop: 0,

    "li &": {
      lineHeight: "inherit",
    },

    "ul + &, ol + &": {
      marginTop: (): string => {
        const fontError = getFontError("body1");
        const spacing = theme.spacing(3);

        return `calc(${spacing}px + ${-fontError.top}px + ${-fontError.bottom}px)`;
      },
    },

    "ul &, ol &": {
      marginBottom: `${liMarginBottom}px`,
    },
    fontSize: "inherit",
    lineHeight: "inherit",
  },
});
