import isEmpty from "lodash/isEmpty";

import {
  ContentModelType,
  IdentifiableContent,
  NoContentfulEntryError,
} from "lib/contentful";

import { getEntryDtoFromFieldValues } from "./utils/get-entry-dto-from-field-values";
import { getEnvironment } from "./utils/get-environment";

export const createAndPublishEntry = async <T extends IdentifiableContent>(
  contentType: ContentModelType,
  fieldValues: T,
) => {
  const env = await getEnvironment();
  const entryCreateDto = getEntryDtoFromFieldValues(fieldValues);
  const entry = await env.createEntry(contentType, entryCreateDto);

  return entry.publish();
};

/**
 *
 * @param contentType @see ContentModelType
 * @param entryIdField It's an entry's `field.id`; it is by our design that an entry is referenced via this id (and NOT via `sys.id`)
 * @param fieldValues an "update" object `{ [fieldId1]: fieldProp1, [fieldId2]: fieldProp2, ... }`
 */
export const updateAndPublishEntry = async <T extends IdentifiableContent>(
  contentType: ContentModelType,
  entryIdField: string,
  fieldValues: T,
) => {
  const env = await getEnvironment();

  let entries;

  // By our design entries are referenced via `field.id` (and NOT by `sys.id`),
  // BUT there's no way to retrieve an entry via querying by `field.id` with `env.getEntry` method.
  // This is why `env.getEntries` method needs to be used instead which does allow for such querying.
  entries = await env.getEntries({
    content_type: contentType,
    "fields.id": entryIdField,
  });

  // Chances are high that there may not be a Contentful entry,
  // which is abnormal during an update operation, so throw a custom error
  if (isEmpty(entries.items)) {
    throw new NoContentfulEntryError(entryIdField);
  }

  const entry = entries.items[0];

  const entryUpdateDto = getEntryDtoFromFieldValues(fieldValues);

  entry.fields = { ...entry.fields, ...entryUpdateDto.fields };

  const updatedEntry = await entry.update();

  return updatedEntry.publish();
};
