export const opacities = {
  basic: {
    default: 0.87,
    disabled: 0.38,
    secondary: 0.56,
  },
  colored: {
    default: 1,
    disabled: 0.5,
    secondary: 0.7,
  },
};
