import React, { ReactElement } from "react";
import clsx from "clsx";
import { Work as WorkIcon } from "@material-ui/icons";
import AssessmentIcon from "@material-ui/icons/Assessment";
import LoyaltyIcon from "@material-ui/icons/Loyalty";
import LocalOfferOutlinedIcon from "@material-ui/icons/LocalOfferOutlined";
import PeopleIcon from "@material-ui/icons/People";

import { Route } from "routes";
import { NavMenuItem } from "ui/molecules/nav-menu-item";
import { textLabels } from "core/constants/text-labels";

import { useStyles } from "./styles";

export const AppSidebar = (): ReactElement => {
  const classes = useStyles({});

  return (
    <div className={clsx(classes.root, classes.navMenu)}>
      <NavMenuItem
        to={Route.EmployerList}
        title={textLabels.employers}
        Icon={WorkIcon}
      />

      <NavMenuItem
        to={Route.TrackingEntity}
        title={textLabels.trackingEntities}
        Icon={AssessmentIcon}
      />

      <NavMenuItem
        to={Route.DiscountBundle}
        title={textLabels.discountBundles}
        Icon={LoyaltyIcon}
      />

      <NavMenuItem
        to={Route.DiscountTier}
        title={textLabels.discountTiers}
        Icon={LocalOfferOutlinedIcon}
      />

      <NavMenuItem
        to={Route.Member}
        title={textLabels.members}
        Icon={PeopleIcon}
      />
    </div>
  );
};
