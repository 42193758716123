/* eslint-disable max-classes-per-file */
import { HTTPError, TimeoutError } from "ky";
import isString from "lodash/isString";
import isNil from "lodash/isNil";

import { BaseErrorPayload, ErrorEvents } from "./types";

export abstract class BaseError<P = unknown> extends Error {
  constructor(public payload?: P, public code: number = 600) {
    super();
  }

  get basePayload(): BaseErrorPayload {
    return { code: this.code, name: this.name };
  }

  dispatchEvent<T>(event: CustomEvent<T>): void {
    window.dispatchEvent(event);
  }

  notify(): void {
    throw new Error("implement notify");
  }
}

export class UnexpectedError<T> extends BaseError<T> {
  notify(): void {
    const customEvent = new CustomEvent(ErrorEvents.UnexpectedError, {
      detail: this.basePayload,
    });

    this.dispatchEvent(customEvent);
  }
}

export class NetworkError<T> extends BaseError<T> {
  notify(): void {
    const customEvent = new CustomEvent(ErrorEvents.NetworkError);

    this.dispatchEvent(customEvent);
  }
}

export class ValidationError<T> extends BaseError<T> {
  notify(): void {
    const customEvent = new CustomEvent(ErrorEvents.ValidationError, {
      detail: this.basePayload,
    });

    this.dispatchEvent(customEvent);
  }
}

export class UnauthorizedError<T> extends BaseError<T> {
  notify(): void {
    const customEvent = new CustomEvent(ErrorEvents.UnauthorizedError, {
      detail: this.basePayload,
    });

    this.dispatchEvent(customEvent);
  }
}

export class TooManyRequestsError<T> extends BaseError<T> {
  notify(): void {
    const customEvent = new CustomEvent(ErrorEvents.TooManyRequestsError, {
      detail: this.basePayload,
    });

    this.dispatchEvent(customEvent);
  }
}

export class InternalServerError<T> extends BaseError<T> {
  notify(): void {
    const customEvent = new CustomEvent(ErrorEvents.InternalServerError, {
      detail: this.basePayload,
    });

    this.dispatchEvent(customEvent);
  }
}

export class RequestTimeoutError<T> extends BaseError<T> {
  notify(): void {
    const customEvent = new CustomEvent(ErrorEvents.RequestTimeoutError, {
      detail: this.basePayload,
    });

    this.dispatchEvent(customEvent);
  }
}

const Errors = {
  400: ValidationError,
  401: UnauthorizedError,
  403: ValidationError,
  429: TooManyRequestsError,
  500: InternalServerError,
};

export const wrapError = async <T>(error: HTTPError): Promise<BaseError<T>> => {
  const isTimeoutError = error instanceof TimeoutError;

  const isNetworkError =
    !isTimeoutError && isString(error.message) && isNil(error.response);

  if (isNetworkError) {
    return new NetworkError();
  }

  if (isTimeoutError) {
    return new RequestTimeoutError();
  }

  const status = error?.response?.status;
  const code = status as keyof typeof Errors;
  const ErrorConstructor = Errors?.[code] ?? UnexpectedError;

  let payload = null;

  try {
    payload = await error.response.json();
  } catch {}

  return new ErrorConstructor<T>(payload, code);
};
