import React, { ReactElement, ReactNode } from "react";
import { Typography } from "@material-ui/core";

import { useStyles } from "./styles";

export const StyledParagraph = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const { paragraph } = useStyles({});

  return (
    <Typography component="p" className={paragraph}>
      {children}
    </Typography>
  );
};
