import { Route } from "routes";
import remoteConfig from "remote-config.json";

import { Config, RemoteConfig } from "./types";

export const appConfig: Config = {
  isDev: process.env.NODE_ENV === "development",
  authRootLink: "https://login.microsoftonline.com",
  dateTimeFormat: "MM/dd/yyyy p",
  dateFormat: "MM/dd/yyyy",
  dateFormatWithoutTimezone: "yyyy-MM-dd'T'HH:mm:ss",
  rawDateFormat: "yyyy-MM-dd",
  maxSnackCount: 1,
  defaultTimeout: 20000,
  initialRoute: Route.EmployerList,
  rangeDateFormat: "MMM - yyyy",
  extraMonths: 1,
  childMemberEffectiveDateEditableMaxExtraDays: 30,
  enrollmentStartDateMaxExtraDays: 60,
  debounceTimeout: 300,
  numberOfDaysBeforeEnrollmentStartDateThatTransactionsAreRun: 1,
  groupMembershipTermDurationMonths: 12,
  renewalOpenEnrollmentPeriodStartDateOffsetFromNextRenewalDateDays: 26,
  renewalOpenEnrollmentPeriodEndDateOffsetFromNextRenewalDateDays: 2,
  membershipTermDaysExtension: 60,

  // remote config
  // @ts-ignore
  ...((window.remoteConfig as RemoteConfig) || (remoteConfig as RemoteConfig)),
};
