import { makeStyles } from "@material-ui/core";

import { CustomTheme } from "ui/theme/material/theme";

export const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    height: "100%",
    padding: theme.spacing(2),
    backgroundColor: theme.colors.background.default,
  },
  navMenu: {
    display: "flex",
    flexDirection: "column",
  },
}));
