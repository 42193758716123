import { stringify } from "qs";

import { ApiUrlsMap } from "api/types";

const memberAccount = "memberAccount";
const memberAccounts = "memberAccounts";
const member = "member";
const contribution = "Contribution";
const bySederaMemberId = "bySederaMemberId";
const rollup = "Rollup";
const getByExternalId = "getByExternalId";
const retrigger = "Retrigger";
const list = "List";
const contributionFeesDiff = "contributionFeesDiff";
const paymentAdjustment = "PaymentAdjustment";
const partialAdjustment = "PartialAdjustment";

/**
 * apiUrlsMap is an object with the following structure:
 *  {
 *    ...
 *    [service]: {
 *      [controller]: {
 *        ...controller endpoints
 *      }
 *    }
 *    ...
 *  }
 */
export const apiUrlsMap: ApiUrlsMap = {
  authService: {
    admin: {
      authorize: {
        get: (): string => "admin/authorize",
      },
      getLoginOnBehalfToken: {
        get: (memberId: string): string =>
          `admin/getLoginOnBehalfToken/${memberId}`,
      },
    },
  },
  enrollmentService: {
    member: {
      get: (): string => "member",
    },
    checkPhone: {
      get: (): string => `checkPhone`,
    },
  },
  productService: {
    product: {
      byExternalId: {
        get: (id: string): string => `byExternalId/${id}`,
      },

      get: () => "",
    },

    discountBundle: {
      lookup: {
        get: (queryString?: string) => `lookup?${queryString}`,
      },

      post: () => "",
      get: () => "",
      put: (externalId: string) => externalId,

      byId: {
        get: (id: string) => id,
      },
    },
    discountTier: {
      lookup: {
        get: (queryString?: string) => `lookup?${queryString}`,
      },

      post: () => "",
      put: (externalId: string) => externalId,
      get: () => "",

      getByExternalId: {
        get: (externalId: string) => `${getByExternalId}/${externalId}`,
      },
    },
  },

  member: {
    substatus: {
      patch: () => `${member}/substatus`,
      transitions: {
        get: () => `${member}/substatus/transitions`,
      },
    },
    memberAccount: {
      get: (memberId: string, isPending = false): string =>
        `${memberAccount}/${memberId}?pending=${isPending}`,
      post: (memberId: string): string => `${memberAccount}/${memberId}`,

      bySederaMemberId: {
        get: (sederaMemberId: string, isPending = false): string =>
          `${memberAccount}/${bySederaMemberId}/${sederaMemberId}?pending=${isPending}`,
        post: (sederaMemberId: string): string =>
          `${memberAccount}/${bySederaMemberId}/${sederaMemberId}`,
      },
    },
    memberAccounts: {
      bySederaMemberId: {
        patch: (memberId: string): string =>
          `${memberAccounts}/${bySederaMemberId}/${memberId}/email`,
      },
    },
    transaction: {
      writeoff: {
        patch: (
          contributionFeeId: number,
          distributionType: "ServiceFeeDistribution" | "AddOnsDistribution",
        ) => `transaction/writeoff`,
      },
    },
    contribution: {
      bySederaMemberId: {
        get: (
          sederaMemberId: string,
          create: boolean,
          billingMonth: number,
          billingYear: number,
        ): string =>
          `${contribution}/${bySederaMemberId}/${sederaMemberId}/?month=${billingMonth}&year=${billingYear}&create=${create}`,
      },
      rollup: {
        bySederaMemberId: {
          get: (sederaMemberId: string, page: number, size: number): string =>
            `${contribution}/${rollup}/${bySederaMemberId}/${sederaMemberId}/?page=${page}&size=${size}`,
        },
      },
      retrigger: {
        post: (
          sederaMemberId: string,
          contributionFeeExternalId: string,
          user: string,
        ): string =>
          `${contribution}/${retrigger}/?MemberAccountId=${sederaMemberId}&ContributionFeeExternalId=${contributionFeeExternalId}&User=${user}`,
      },
      list: {
        bySederaMemberId: {
          get: (sederaMemberId: string) =>
            `${contribution}/${list}/${bySederaMemberId}/${sederaMemberId}`,
        },
      },
      contributionFeesDiff: {
        get: (firstContributionId: string, secondContributionId: string) =>
          `${contribution}/${contributionFeesDiff}/?FirstContributionId=${firstContributionId}&SecondContributionId=${secondContributionId}`,
      },
      writeOffContribution: {
        patch: () => `${contribution}/WriteOffContribution`,
      },
    },
    paymentAdjustment: {
      partialAdjustment: {
        post: (memberId: string) =>
          `${paymentAdjustment}/${partialAdjustment}/${memberId}`,
        delete: (adjustmentId: number) =>
          `${paymentAdjustment}/${adjustmentId}`,
      },
    },
  },

  employerService: {
    employer: {
      post: (): string => "",
      get: (queryString?: string): string => queryString,
      put: (id: string): string => `${id}`,

      getByExternalId: {
        get: (id: string): string => `getByExternalId/${id}`,
      },

      lookup: {
        get: () => "lookup",
      },

      contribution: {
        summary: {
          get: (id, options: Record<string, unknown>): string =>
            `${id}/ContributionSummary${stringify(options, {
              addQueryPrefix: true,
            })}`,
        },

        details: {
          get: (id, options: Record<string, unknown>): string =>
            `${id}/ContributionDetails${stringify(options, {
              addQueryPrefix: true,
            })}`,
        },
      },

      invoice: {
        delete: () => "",
        get: () => "",
        post: () => "",

        summary: {
          get: (id, options: Record<string, unknown>): string =>
            `${id}/InvoiceSummary${stringify(options, {
              addQueryPrefix: true,
            })}`,
        },

        exportPdf: {
          get: (): string => `export/pdf`,
        },

        history: {
          get: (): string => `history`,
        },

        details: {
          get: (id, options: Record<string, unknown>): string =>
            `${id}/InvoiceDetails${stringify(options, {
              addQueryPrefix: true,
            })}`,
        },
      },
    },
  },
  acbService: {
    info: {
      get: (memberId: string): string => `Info/${memberId}`,
    },
    kyc: {
      documents: {
        get: (memberId: string): string => `Kyc/${memberId}/documents`,
      },
    },
  },
  affiliateService: {
    affiliate: {
      lookup: {
        get: (affiliateType: string) => `lookup/?type=${affiliateType}`,
      },
    },

    internalSales: {
      lookup: {
        get: () => "lookup",
        post: () => "GetByExternalId",
      },
    },

    directPrimaryCare: {
      lookup: {
        get: () => "lookup",
      },
      byDiscountTiers: {
        get: () => "",
      },
    },

    minimumEssentialCoverage: {
      lookup: {
        get: () => "lookup",
      },
      byDiscountTiers: {
        get: () => "",
      },
    },

    trackingEntity: {
      post: () => "",
      get: (queryString?: string) => `${queryString}`,

      details: {
        get: (id: string) => `${id}/details`,
      },

      byExternalId: {
        get: (id: string) => `getByExternalId/${id}`,
        put: (id: string) => `updateByExternalId/${id}`,
      },

      byEmployerExternalId: {
        get: (id: string) => `getByEmployerExternalId/${id}`,
      },

      byDiscountBundleExternalId: {
        get: (discountBundleExternalId: string) =>
          `getByDiscountBundleExternalId/${discountBundleExternalId}`,
      },
    },
  },

  sfdc: {
    auth: {
      post: () => `oauth2/token`,
    },
    webhook: {
      post: () => `apexrest/AzureHook/`,
    },
  },

  selfServiceTool: {
    accessRefund: {
      post: () => `AccessPlusRefund`,
    },
  },
};
