import React, { memo, ReactElement } from "react";
import {
  Box,
  Button,
  Link,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import PermIdentityOutlinedIcon from "@material-ui/icons/PermIdentityOutlined";
import { useSelector } from "react-redux";

import { useMenu } from "ui/utils/hooks";
import { getFullUsernameSelector } from "store/selectors";
import { textLabels } from "core/constants/text-labels";
import { appConfig } from "app-config";
import { useContentfulEntry } from "lib/contentful/delivery";
import { Config, ConfigId, ContentModelType } from "lib/contentful";

import { useStyles } from "./app-header.styles";

export const AppHeader = memo(
  ({ signOut }: { signOut: () => void }): ReactElement => {
    const classes = useStyles({});
    const fullName = useSelector(getFullUsernameSelector);

    const { data: config } = useContentfulEntry<Config>(
      ContentModelType.Config,
      ConfigId.GeneralConfig,
    );

    const { isOpen, anchorEl, handleOpen, handleClose } = useMenu<
      HTMLButtonElement
    >();

    return (
      <div className={classes.root}>
        <Link className={classes.title} href={appConfig.initialRoute}>
          <Box display="flex">
            <img
              className={classes.logo}
              src={config?.logo?.file?.url}
              alt="sedera-logo"
            />
          </Box>
        </Link>

        <Box
          display="flex"
          alignItems="center"
          aria-controls="header-profile-menu"
          aria-haspopup="true"
          onClick={handleOpen}
        >
          <Button color="primary">
            <PermIdentityOutlinedIcon />

            <Box marginLeft={1}>
              <Typography>{fullName}</Typography>
            </Box>
          </Button>
        </Box>

        <Menu
          id="header-profile-menu"
          open={isOpen}
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          onClose={handleClose}
        >
          <MenuItem onClick={signOut}>{textLabels.signOut}</MenuItem>
        </Menu>
      </div>
    );
  },
);
