import { EntryFields } from "contentful";
import useSWR from "swr";

import { ContentModelType, IdentifiableContent } from "../types";
import { getEntries } from "./api/methods";
import { entryIdentifierPath } from "./api/utils";

/**
 * Returns Entry object with all linked entries by {@param entryId}
 *
 * @param entryType
 * @param entryId
 */
export const useContentfulEntry = <T extends IdentifiableContent>(
  entryType: ContentModelType,
  entryId: EntryFields.Text,
): { data: T; revalidate: () => Promise<boolean> } => {
  const { data, revalidate } = useSWR([entryType, entryId], (type, id) =>
    getEntries<T>(type, { [entryIdentifierPath]: id }),
  );

  return { data: data?.[0], revalidate };
};
