import transitions from "@material-ui/core/styles/transitions";

export const predefinedAnimations = {
  default: `${transitions.duration.standard}ms ${transitions.easing.sharp}`,
  short: `${transitions.duration.short}ms ${transitions.easing.sharp}`,
};

export const defaultFadeTimeout = {
  exit: 0,
  enter: transitions.duration.standard,
};
