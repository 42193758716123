import { createMuiTheme, Theme } from "@material-ui/core/styles";
import { ThemeOptions } from "@material-ui/core/styles/createMuiTheme";

import { palette, PaletteType } from "./palette";
import { typography } from "./typography";
import { Colors, colors } from "../colors";
import { buttonStyles } from "./components/button";
import { checkboxStyles } from "./components/checkbox";
import { stepConnectorStyles } from "./components/step-connector";
import { Config, config } from "../config";
import { formLabelStyles } from "./components/form-label";
import { skeletonStyles } from "./components/skeleton";
import { inputLabelStyles } from "./components/input-label";
import { filledInputStyles } from "./components/filled-input";
import { snackbarContentStyles } from "./components/snackbar-content";
import { iconButtonStyles } from "./components/icon-button";
import { tooltipStyles } from "./components/tooltip";
import { menuItemStyles } from "./components/menu-item";
import { linkStyles } from "./components/link";
import { listStyles } from "./components/list";
import { svgIconStyles } from "./components/icon";
import { paperStyles } from "./components/paper";
import { listItemStyles } from "./components/list-item";
import { autocompleteStyles } from "./components/autocomplete";

export interface CustomThemeOptions extends ThemeOptions {
  colors: Colors;
  config: Config;
}

const themeOptions = {
  palette,
  colors,
  typography,
  config,
  overrides: {
    MuiButton: buttonStyles,
    MuiAutocomplete: autocompleteStyles,
    MuiCheckbox: checkboxStyles,
    MuiStepConnector: stepConnectorStyles,
    MuiInputLabel: inputLabelStyles,
    MuiFilledInput: filledInputStyles,
    MuiFormLabel: formLabelStyles,
    MuiSnackbarContent: snackbarContentStyles,
    MuiIconButton: iconButtonStyles,
    MuiTooltip: tooltipStyles,
    MuiMenuItem: menuItemStyles,
    MuiTextField: filledInputStyles,
    MuiLink: linkStyles,
    MuiList: listStyles,
    MuiSvgIcon: svgIconStyles,
    MuiPaper: paperStyles,
    MuiSkeleton: skeletonStyles,
    MuiListItem: listItemStyles,
  },
  shape: {
    borderRadius: config.borderRadius,
  },
} as CustomThemeOptions;

export type CustomTheme = Theme & CustomThemeOptions;

export const theme = createMuiTheme(themeOptions) as CustomTheme;

export const createThemeByPalette = (
  paletteType: PaletteType,
  customOptions: object = {},
): any => ({
  ...theme,
  palette: {
    ...theme.palette,
    primary: {
      ...((theme.palette as any)[paletteType] as object),
    },
    ...customOptions,
  },
});
