import React from "react";
import { CircularProgress, Box } from "@material-ui/core";

export const Loader = ({ height = "100vh" }: { height?: string }) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height={height}
    >
      <CircularProgress />
    </Box>
  );
};
