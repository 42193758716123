import { createSelector } from "reselect";

import { initialState } from "./state";
import { State } from "./types";

export const getId = (state: State, id: string) => id;

export const getState = (state = initialState): State => state;

export const getAuthStateSelector = createSelector(
  getState,
  (state) => state.auth,
);

export const getFullUsernameSelector = createSelector(
  getAuthStateSelector,
  (user) => user.fullName,
);

export const getProductsSelector = createSelector(
  getState,
  (state) => state.products,
);

export const getProductByIdSelector = createSelector(
  [getProductsSelector, getId],
  (products, id) => products?.[id],
);
