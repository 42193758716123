import {
  fade,
  PaletteColorOptions,
  SimplePaletteColorOptions,
} from "@material-ui/core/styles";

import { opacities } from "./opacities";
import { config } from "./config";

const bg = "#f6f7f9";
const darkenBg = "#edeff3";
const headerBg = `rgba(255, 255, 255, ${config.headerOpacity})`;
const transparentBg = `rgba(255, 255, 255, 0.5)`;

const ripple = {
  lightBg: "#ccd2df",
  darkBg: "9fabc2",
};

const primary: PaletteColorOptions = {
  light: "#4b7fb5",
  main: "#085385",
  dark: "#002b58",
  contrastText: "#fff",
};

const secondary: PaletteColorOptions = {
  light: "#6ae4ff",
  main: "#1bb2e3",
  dark: "#168EB6",
  contrastText: "#fff",
};

const warning: SimplePaletteColorOptions = {
  light: "#ffe25d",
  main: "#fdb027",
  dark: "#c58100",
  contrastText: "#fff",
};

const error: SimplePaletteColorOptions = {
  light: "#ff6762",
  main: "#d33138",
  dark: "#9a0012",
  contrastText: "#fff",
};

const success: SimplePaletteColorOptions = {
  light: "#4fc49d",
  main: "#00936f",
  dark: "#006444",
  contrastText: "#fff",
};

const white: SimplePaletteColorOptions = {
  light: "#fff",
  main: "#fff",
  dark: "#f0fcff",
  contrastText: secondary.main,
};

export interface Colors {
  primary: SimplePaletteColorOptions;
  secondary: SimplePaletteColorOptions;
  success: SimplePaletteColorOptions;
  error: SimplePaletteColorOptions;
  warning: SimplePaletteColorOptions;
  white: SimplePaletteColorOptions;
  fadedPrimary: string;
  background: {
    default: string;
    darken: string;
    header: string;
    transparent: string;
  };
  input: {
    background: string;
    backgroundHover: string;
    errorBackground: string;
    errorBackgroundHover: string;
  };
  ripple: {
    lightBg: string;
    darkBg: string;
  };
}

export const colors: Colors = {
  primary,
  secondary,
  warning,
  error,
  success,
  white,
  ripple,
  fadedPrimary: fade(primary.main, opacities.colored.secondary),
  background: {
    default: bg,
    darken: darkenBg,
    header: headerBg,
    transparent: transparentBg,
  },
  input: {
    background: bg,
    backgroundHover: darkenBg,
    errorBackground: fade(error.main, 0.1),
    errorBackgroundHover: fade(error.main, 0.2),
  },
};
