import { colors } from "../../colors";

export const stepConnectorStyles = {
  line: {
    borderColor: colors.background.darken,
    width: "2px",
    flex: 1,
  },
  root: {
    "&$vertical": {
      paddingTop: "0.25rem",
      marginLeft: "0.5rem",
      display: "flex",
    },
  },
};
