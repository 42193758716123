export enum NotificationType {
  /**
   * Set notification type to `'info'`
   */
  Info = "info",

  /**
   * Set notification type to `'success'`
   */
  Success = "success",

  /**
   * Set notification type to `'warning'`
   */
  Warning = "warning",
  /**
   * Set notification type to `'error'`
   */
  Error = "error",

  /**
   * Set notification type to `'default'`
   */
  Default = "default",
}
