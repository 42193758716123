import makeStyles from "@material-ui/styles/makeStyles";

import { CustomTheme } from "../../../theme/material/theme";

export const useStyles = makeStyles((theme: CustomTheme) => ({
  li: {
    position: "relative",

    "ul &::before": {
      content: '""',
      position: "absolute",
      width: "6px",
      height: "6px",
      left: "-21px",
      marginTop: "1px",
      transform: "translateY(100%)",
      borderRadius: "50%",
      display: "block",
      backgroundColor: theme.colors.primary.main,
    },
  },
}));
