import { fade } from "@material-ui/core/styles";

import { colors } from "../../colors";
import { opacities } from "../../opacities";
import { Shadow } from "../shadows";

const height = 36;

export const buttonStyles = {
  root: {
    height: `${height}px`,
    padding: "4px 16px",
  },
  contained: {
    backgroundColor: colors.background.default,
    borderRadius: `${height / 2}px`,
    boxShadow: Shadow.None,
    textTransform: "none",
    color: colors.primary.main,

    "&:active": {
      boxShadow: Shadow.None,
    },
    "&:focus": {
      boxShadow: Shadow.None,
    },
    "&:hover": {
      backgroundColor: colors.background.darken,
      boxShadow: Shadow.None,

      "@media (hover: none)": {
        boxShadow: Shadow.None,
      },
    },
    "&$disabled": {
      color: fade(colors.primary.main, opacities.colored.disabled),
      backgroundColor: colors.background.default,
    },
  },
  outlined: {
    borderRadius: `${height / 2}px`,
  },
  text: {
    borderRadius: `${height / 2}px`,
    padding: "0 14px",
  },
};
