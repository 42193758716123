import { extendedProducts } from "features/extended-products/state";

import { Auth, State } from "./types";
import { initialState as products } from "./products/state";

export const initialAuthState: Auth = {
  fullName: null,
};

export const initialState: State = {
  auth: initialAuthState,
  products,
  extendedProducts,
};
