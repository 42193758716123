import { config } from "../../config";
import { colors } from "../../colors";
import { typography } from "../typography";

export const menuItemStyles = {
  root: {
    height: "48px",
    borderRadius: config.borderRadius,
    color: colors.primary.main,
    fontWeight: typography.fontWeightRegular,

    "&$selected": {
      fontWeight: typography.fontWeightMedium,
      color: colors.secondary.main,
      backgroundColor: colors.background.default,
    },
  },
};
