import { useHistory } from "react-router-dom";
import { useCallback } from "react";

import { Route } from "routes";

import { logout } from "./utils";

export const useLogout = () => {
  const history = useHistory();

  return useCallback(() => {
    logout();
    history.push(Route.Root);
  }, [history]);
};
