interface ObjWithValuesSubkeyed {
  [key: string]: { [subkey: string]: any };
}

export const getObjWithValuesSubkeyed = <T>(fieldValues: T, subkey: string) => {
  return Object.entries(fieldValues).reduce((acc, [key, value]) => {
    acc[key] = {
      [subkey]: value,
    };

    return acc;
  }, {} as ObjWithValuesSubkeyed);
};
