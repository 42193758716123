import { useState, useMemo, useCallback } from "react";

import { UseMenuReturnType } from "./types";

export const useMenu = <T extends HTMLElement>(): UseMenuReturnType<T> => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return useMemo<UseMenuReturnType<T>>(
    () => ({
      isOpen: Boolean(anchorEl),
      anchorEl,
      handleOpen,
      handleClose,
    }),
    [anchorEl, handleClose, handleOpen],
  );
};
