import { config } from "../../config";

export const listStyles = {
  padding: {
    // Need to write separately to override them. Since Material-UI specifies them separately
    paddingTop: `${config.outlineElementBorderWidth}px`,
    paddingRight: `${config.outlineElementBorderWidth}px`,
    paddingBottom: `${config.outlineElementBorderWidth}px`,
    paddingLeft: `${config.outlineElementBorderWidth}px`,
  },
};
