/**
 * Enum for reducer with loading.
 * @readonly
 * @enum {string}
 * @property {string} Init - init at initialState of reducer
 * @property {string} InitLoading - marks first loading data
 * @property {string} Done - marks end of loading data
 * @property {string} Loading - marks loading data except first
 */
export enum LoadingStatus {
  Init = "Init",
  InitLoading = "InitLoading",
  Done = "Done",
  Loading = "Loading",
}
