import makeStyles from "@material-ui/styles/makeStyles";
import { CSSProperties } from "@material-ui/styles";

import { CustomTheme } from "ui/theme/material/theme";

import { NavMenuItemStyleProps } from "./types";

export const useStyles = makeStyles<CustomTheme, NavMenuItemStyleProps>(
  (theme) => ({
    icon: {
      marginRight: theme.spacing(1.5),
    },
    text: {
      lineHeight: "19px",
    },
    wrappingLink: {
      textDecoration: "none",
    },
    wrapper: ({ isActive }): CSSProperties => ({
      position: "relative",
      color: isActive ? theme.colors.secondary.main : theme.colors.fadedPrimary,
    }),
    button: ({ isActive }): CSSProperties => ({
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      cursor: "pointer",
      alignItems: "center",
      paddingRight: theme.spacing(1.75),
      paddingLeft: theme.spacing(1.75),
      height: theme.spacing(6),
      backgroundColor: isActive
        ? theme.colors.background.darken
        : "transparent",
      borderRadius: theme.shape.borderRadius,
      transition: `background-color ${theme.transitions.duration.standard}ms, color ${theme.transitions.duration.standard}ms`,

      "&:hover, &:focus": {
        backgroundColor: theme.palette.action.hover,
      },
    }),
  }),
);
