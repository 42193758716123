import React from "react";
import { Typography } from "@material-ui/core";

import { useStyles } from "./unauthenticated.styles";

export const Unauthenticated = ({
  children,
  text,
}: {
  children: React.ReactNode;
  text: string;
}) => {
  const classes = useStyles({});

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Typography variant="h6" align="center" color="primary">
          {text}
        </Typography>
        {children}
      </div>
    </div>
  );
};
