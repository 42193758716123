import React, { ReactElement } from "react";
import { useRouteMatch, Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";

import { NavMenuItemProps } from "./types";
import { useStyles } from "./styles";

export const NavMenuItem = ({
  to,
  title,
  Icon,
}: NavMenuItemProps): ReactElement => {
  const match = useRouteMatch({
    path: to,
    exact: true,
  });

  const classes = useStyles({ isActive: Boolean(match) });

  return (
    <Link to={to} className={classes.wrappingLink}>
      <div className={classes.wrapper}>
        <ButtonBase classes={{ root: classes.button }}>
          <Icon className={classes.icon} />

          <Typography variant="subtitle2" className={classes.text}>
            {title}
          </Typography>
        </ButtonBase>
      </div>
    </Link>
  );
};

NavMenuItem.displayName = "NavMenuItem";
