import React, { ReactElement } from "react";
import { Typography } from "@material-ui/core";

import { useStyles } from "./styled-subtitle2.style";

export const StyledSubtitle2 = ({
  children,
}: {
  children: React.ReactNode;
}): ReactElement => {
  const { subtitle } = useStyles({});

  return (
    <Typography component="div" variant="subtitle2" className={subtitle}>
      {children}
    </Typography>
  );
};
