import { useEffect } from "react";

import { timeoutEventName } from "core/constants/timeout";
import { useLogout } from "features/auth/use-logout";

export function useLogoutTimeoutHandler(): void {
  const logout = useLogout();

  useEffect(() => {
    window.addEventListener(timeoutEventName, logout);

    return (): void => {
      window.removeEventListener(timeoutEventName, logout);
    };
  });
}
