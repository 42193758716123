import React, {
  ReactElement,
  useCallback,
  ChangeEvent,
  FocusEvent,
  memo,
} from "react";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import { useField } from "formik";
import ArrowDropDownRoundedIcon from "@material-ui/icons/ArrowDropDownRounded";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import isEmpty from "lodash/isEmpty";

import { hasFieldError } from "lib/form/has-field-error";

import { AutocompleteFieldProps } from "./types";
import { SelectOption } from "../../../types/select-option";

export const AutocompleteField = memo(
  <T extends SelectOption<string>>({
    freeSolo = true,
    options,
    name,
    label,
    required,
    inputProps,
    InputProps,
    getOptionLabel = (option: SelectOption<string>): string => option.value,
    openOnFocus = true,
    ...rest
  }: AutocompleteFieldProps<T>): ReactElement => {
    const [field, { touched, error }, { setValue, setTouched }] = useField(
      name,
    );

    const hasError = hasFieldError(touched, error);

    const value =
      options.find(
        (option: SelectOption<string>) => option.value === field.value,
      ) ?? null;

    const setFieldTouched = useCallback(
      (): void => setTouched(true),
      // The following deps won't change, so don't include them: setTouched
      [setTouched],
    );

    const handleAutocompleteChange = useCallback(
      (_: ChangeEvent<{}>, selectedOption: T): void => {
        setValue(selectedOption?.value ?? null);
      },
      // The following deps won't change, so don't include them: setValue
      [setValue],
    );

    const setValueByUserInput = useCallback(
      (inputValue: any) => {
        if (!isEmpty(inputValue)) {
          const selectedOption = options.find(
            (option: SelectOption<string>) =>
              option.value === inputValue || option.label === inputValue,
          );

          setValue(selectedOption?.value ?? null);
        }
      },
      // The following deps won't change, so don't include them: setValue
      [options, setValue],
    );

    const handleBlur = useCallback(
      (event: FocusEvent<HTMLInputElement>) => {
        setFieldTouched();

        // Because of the "freeSolo" prop provided to the Autocomplete, it allows to set any value typed into the input.
        // This way, when the input is blurred a check for an option matching the user-entered value is performed.
        // And if the entered value matches label or value of any option the latter becomes a selected one.
        // This functionality is lacking in the Material UI lib ATTOW (or not planned altogether) and was implemented ad-hoc.
        setValueByUserInput(event.currentTarget.value);
      },
      // The following deps won't change, so don't include them: setFieldTouched
      [setFieldTouched, setValueByUserInput],
    );

    const renderInput = useCallback(
      (parameters): ReactElement => {
        return (
          <TextField
            {...parameters}
            required={required}
            error={hasError}
            variant="filled"
            label={label}
            inputProps={{
              ...parameters.inputProps,
              ...inputProps,
              autoComplete: "new-password",
            }}
            InputProps={{
              ref: parameters.InputProps.ref,
              ...InputProps,
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            onBlur={handleBlur}
          />
        );
      },
      // The following deps won't change, so don't include them: intl, setFieldTouched
      [required, hasError, label, handleBlur, inputProps, InputProps],
    );

    return (
      <Autocomplete
        freeSolo={freeSolo}
        openOnFocus={openOnFocus}
        getOptionLabel={getOptionLabel}
        value={value}
        options={options}
        popupIcon={<ArrowDropDownRoundedIcon />}
        closeIcon={<CloseOutlinedIcon />}
        renderInput={renderInput}
        onInputChange={setFieldTouched}
        onChange={handleAutocompleteChange as any}
        {...rest}
      />
    );
  },
);
