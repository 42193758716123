import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunkMiddleware from "redux-thunk";

import { extendedProductsSlice } from "features/extended-products";
import { appConfig } from "app-config";

import { reducer as productsReducer } from "./products/reducer";
import { reducer as authReducer } from "./reducers";
import { initialState } from "./state";

const rootReducer = combineReducers({
  auth: authReducer,
  products: productsReducer,
  extendedProducts: extendedProductsSlice.reducer,
});

const middleware = appConfig.isDev
  ? composeWithDevTools(applyMiddleware(thunkMiddleware))
  : applyMiddleware(thunkMiddleware);

export const store = createStore(rootReducer, initialState, middleware);
