export enum Route {
  Root = "/",
  Member = "/member",
  MemberView = "/member/:memberId/sederaMember/:sederaMemberId",
  TrackingEntity = "/tracking-entity/",
  TrackingEntityView = "/tracking-entity/:id",
  TrackingEntityCreate = "/tracking-entity/create",
  EmployerCreate = "/employer/create",
  EmployerList = "/employer",
  EmployerViewEdit = "/employer/:id",
  DiscountBundle = "/discount-bundle",
  DiscountBundleCreate = "/discount-bundle/create",
  DiscountBundleView = "/discount-bundle/:id",
  DiscountTier = "/discount-tier",
  DiscountTierCreate = "/discount-tier/create",
  DiscountTierView = "/discount-tier/:id",
}
