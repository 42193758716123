import { createAsyncThunk } from "@reduxjs/toolkit";

import { getApiClient } from "api/client";
import { apiUrlsMap } from "api/urls";
import { appConfig } from "app-config";
import { ProductDto } from "features/product/api/dto";
import { AuthToken } from "features/auth/types";

const apiClient = getApiClient({
  basePath: `${appConfig.productServiceUrl}/product`,
  authToken: AuthToken.Product,
});

export const getExtendedProductsThunk = createAsyncThunk<
  ProductDto[],
  string[]
>("[productsExternal] getExtendedProductsThunk", async (props) => {
  return Promise.all(
    props.map((id) =>
      apiClient.get<ProductDto>(
        apiUrlsMap.productService.product.byExternalId.get(id),
      ),
    ),
  );
});
