import { fade } from "@material-ui/core/styles";

export const shadowPrimaryColor = "#42526e";

export enum Shadow {
  Long = "long",
  Short = "short",
  ShortReverse = "shortReverse",
  ExtraLong = "extraLong",
  Colored = "colored",
  Dialog = "dialog",
  None = "none",
}

const defaultShadowOpacity = 0.08;

export const shadows = {
  [Shadow.Long]: `0px 8px 24px ${fade(
    shadowPrimaryColor,
    defaultShadowOpacity,
  )}`,
  [Shadow.Short]: `0 8px 16px 0 ${fade(
    shadowPrimaryColor,
    defaultShadowOpacity,
  )}`,
  [Shadow.ShortReverse]: `0 -8px 16px 0 ${fade(
    shadowPrimaryColor,
    defaultShadowOpacity,
  )}`,
  [Shadow.ExtraLong]: `0 8px 32px 0 ${fade(
    shadowPrimaryColor,
    defaultShadowOpacity,
  )}`,
  [Shadow.Colored]: `0 8px 16px 0 ${fade(shadowPrimaryColor, 0.15)}`,
  [Shadow.Dialog]: `0 8px 16px 0 ${fade(shadowPrimaryColor, 0.25)}`,
  [Shadow.None]: "none",
};
