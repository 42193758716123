import React, { ReactElement, ReactNode } from "react";
import clsx from "clsx";

import { useStyles } from "./styles";

export const StyledUnorderedList = ({
  children,
  className = "",
}: {
  children: ReactNode;
  className?: string;
}): ReactElement => {
  const { ul } = useStyles({});

  return <ul className={clsx(ul, className)}>{children}</ul>;
};
