import makeStyles from "@material-ui/styles/makeStyles";

import { getFontError } from "../../../theme/utils/get-font-error";
import { theme } from "../../../theme/material/theme";

export const useStyles = makeStyles({
  ul: {
    paddingLeft: "21px",
    listStyle: "none",

    "p + &": {
      marginTop: (): string => {
        const fontError = getFontError("body1");
        const spacing = theme.spacing(3);

        return `calc(${spacing}px + ${-fontError.top}px + ${-fontError.bottom}px)`;
      },
      marginBottom: 0,
    },

    "& > li": {
      "&:last-child": {
        "& > p": {
          marginBottom: 0,
        },
      },
    },
  },
});
