import { makeStyles } from "@material-ui/core";
import { Variant } from "@material-ui/core/styles/createTypography";

import { opacities } from "ui/theme/opacities";
import { DecoratedTypographyProps } from "ui/atoms/decorated-typography/decorated-typography.types";

import { getFontError } from "../../theme/utils/get-font-error";
import { CustomTheme } from "../../theme/material/theme";

export const useStyles = makeStyles<CustomTheme, DecoratedTypographyProps>(
  (): any => ({
    root: {
      marginTop: ({ variant }: { variant: Variant }): string =>
        `${-getFontError(variant).top}px`,
      opacity: ({ disabled }: { disabled: boolean }): number =>
        disabled ? opacities.colored.disabled : 1,
      userSelect: ({ disabled }: { disabled: boolean }) =>
        disabled ? "none" : "auto",
    },
    spacer: {
      height: "1px",
      marginTop: ({ variant }: { variant: Variant }): string =>
        `${-(getFontError(variant).bottom + 1)}px`,
    },
  }),
);
