import { colors } from "../../colors";
import { opacities } from "../../opacities";

export const checkboxContainerSize = 40;
export const checkboxSvgContainerSize = 24;
export const checkboxSvgIconSize = 18; // measurement taken from design mockup

export const checkboxStyles = {
  root: {
    width: checkboxContainerSize,
    height: checkboxContainerSize,
    "& svg path": {
      d:
        'path("M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z")',
    },
    "& svg": {
      width: checkboxSvgContainerSize,
      height: checkboxSvgContainerSize,
    },
    "&$disabled": {
      "& svg": {
        opacity: opacities.basic.disabled,
      },
    },
    "&.Mui-checked": {
      "& svg path": {
        d:
          'path("M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14zM17.99 9l-1.41-1.42-6.59 6.59-2.58-2.57-1.42 1.41 4 3.99z")',
      },
      "& svg": {
        fill: "currentColor",
      },
    },
  },
  indeterminate: {
    "& svg": {
      fill: colors.primary.main,
    },
    "& svg path": {
      d:
        'path("M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14zM7 11h10v2H7z")',
    },
  },
};
