import { ProductAction } from "./constants";
import { ProductActionType } from "./actions";
import { initialState } from "./state";

export const reducer = (state = initialState, action: ProductActionType) => {
  switch (action.type) {
    case ProductAction.GetProductSuccess:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};
