// @ts-ignore
import buildURL from "axios/lib/helpers/buildURL";
import isNil from "lodash/isNil";
import { AxiosPromise, AxiosRequestConfig, AxiosResponse } from "axios";
import omit from "lodash/omit";

/**
 * Simple key-value storage to avoid repetitive content requests
 *
 * Where:
 *  key - request url
 *  value - response data
 *
 * To get cached value, simply invoke `cache[requestUrl]`
 * To know whether value is already cached for specific key(url), check if `cache[requestUrl]`
 * is not undefined.
 *
 * Cache is persisted in Browser memory and will be re-initialized once browser refreshed
 */
const cache: { [url: string]: object } = {};

/**
 * Cache Contentful requests only for GET methods.
 * @param config
 */
const shouldCache = (config: AxiosRequestConfig): boolean =>
  config.method === "get" && config.params.shouldCache !== false;

/**
 * Build URL from baseURL, params using paramsSerializer.
 *
 * During request `url` and `baseURL` are not yet concatenated. While during respose `url` results
 * into desired link.
 *
 * @param requestConfig
 * @param isInvokedDuringRequest
 */
const getFullURL = (
  requestConfig: AxiosRequestConfig,
  isInvokedDuringRequest = false,
): string => {
  const { url, baseURL, params, paramsSerializer } = requestConfig;

  const concatenatedURL = isInvokedDuringRequest ? `${baseURL}/${url}` : url;

  return buildURL(concatenatedURL, params, paramsSerializer);
};

/**
 * Intercepts Contentful request to check whether requested content is already cached.
 * If true, mocks response without doing actual network request.
 *
 * @param requestConfig
 */
const requestInterceptor = (
  requestConfig: AxiosRequestConfig,
): AxiosRequestConfig => {
  const _shouldCache = shouldCache(requestConfig);

  // eslint-disable-next-line no-param-reassign
  requestConfig.params = {
    ...omit(requestConfig.params, "shouldCache"),
  };

  if (!_shouldCache) {
    return requestConfig;
  }

  const fullURL = getFullURL(requestConfig, true);
  const cachedValue = cache[fullURL];
  const isCached = !isNil(cachedValue);

  if (isCached) {
    /* eslint-disable no-param-reassign */

    // Need to preserve reference of requestConfig and do not create
    // a copy.
    // Making separate reference produces http request
    requestConfig.data = cachedValue;
    requestConfig.adapter = (): AxiosPromise<any> => {
      return Promise.resolve({
        data: cachedValue,
        status: undefined,
        statusText: undefined,
        headers: requestConfig.headers,
        config: requestConfig,
        request: requestConfig,
      });
    };
    /* eslint-enable no-param-reassign */
  }

  return requestConfig;
};

/**
 * Adds response data to cache if requested content is not already in storage.
 *
 * @param response
 */
const responseInterceptor = (response: AxiosResponse): AxiosResponse => {
  const { config: requestConfig, data } = response;
  const fullURL = getFullURL(requestConfig);
  const cachedValue = cache[fullURL];
  const isCached = !isNil(cachedValue);

  if (!isCached && shouldCache(requestConfig)) {
    cache[fullURL] = data;
  }

  return response;
};

export const interceptorsConfig = {
  requestLogger: requestInterceptor,
  responseLogger: responseInterceptor,
};
