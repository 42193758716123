import {
  Variant,
  TypographyOptions,
} from "@material-ui/core/styles/createTypography";

export interface CustomTypographyOptions extends TypographyOptions {
  fontVariant: Variant | "srOnly" | "inherit";
}

export const typography: CustomTypographyOptions = {
  h1: {
    fontSize: "72px",
    fontWeight: 700,
  },
  h5: {
    fontSize: "24px",
    lineHeight: "32px",
    fontWeight: 600,
  },
  h6: {
    fontSize: "20px",
    lineHeight: "24px",
    fontWeight: 600,
  },
  button: {
    fontSize: "16px",
    lineHeight: "20px",
    fontWeight: 600,
    textTransform: "none",
  },
  subtitle1: {
    fontSize: "18px",
    lineHeight: "20px",
    fontWeight: 600,
  },
  subtitle2: {
    fontWeight: 600,
  },
  body1: {
    fontSize: "16px",
    lineHeight: "20px",
  },
  caption: {
    fontSize: "13px",
    lineHeight: "16px",
  },
  fontSize: 16,
  fontFamily: ["Open Sans", "sans-serif"].join(", "),
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  fontVariant: "body1",
};

export const pageTitleFontFamily = ["Roboto Slab", "sans-serif"].join(", ");
