import React, { memo, ReactElement } from "react";
import clsx from "clsx";

import { BaseTemplateProps } from "./types";
import { useStyles } from "./styles";
import { baseTemplateContentId } from "./shared/constants/element-ids";

export const BaseTemplate = memo<BaseTemplateProps>(
  ({ children, header, sidebar }): ReactElement => {
    const classes = useStyles({});

    return (
      <div className={classes.wrapper}>
        <div className={classes.grid}>
          <div className={classes.header}>{header}</div>
          <div className={clsx(classes.sidebar, classes.column)}>{sidebar}</div>
          <div
            id={baseTemplateContentId}
            className={clsx(classes.main, classes.column)}
          >
            {children}
          </div>
        </div>
      </div>
    );
  },
);

BaseTemplate.displayName = "BaseTemplate";
