import { defaultLocale, IdentifiableContent } from "lib/contentful";
import { getObjWithValuesSubkeyed } from "utils/object/get-obj-with-values-subkeyed";

export const getEntryDtoFromFieldValues = <T extends IdentifiableContent>(
  fieldValues: T,
) => {
  return {
    fields: getObjWithValuesSubkeyed(fieldValues, defaultLocale),
  };
};
