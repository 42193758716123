export const textLabels = {
  sedera: "Sedera",
  signOut: "Sign Out",
  noAccessMessage: "You don't have access. To proceed, please, sign in.",
  mainError: "Something went wrong",
  email: "Email",
  password: "Password",
  all: "All",
  ok: "Ok",
  close: "Close",
  save: "Save",
  cancel: "Cancel",
  create: "Create",
  edit: "Edit",
  fieldNotValid: "Field is invalid",
  valueHasInvalidFormat: (value: string): string =>
    `${value} has invalid format`,
  required: "Required",
  invalidFormat: "Invalid Format",
  dateShouldBeLowerOrEqualThan: (value: string) =>
    `Date should be lower or equal than ${value}`,
  dateShouldBeGreaterOrEqualThan: (value: string) =>
    `Date should be greater or equal than ${value}`,
  maxDoB: (value: number | string) => `Should be younger than ${value} y.o.`,
  minDoB: (value: number | string) => `Shouldn't be younger than ${value} y.o.`,
  dateGreaterOrEqualThan: (value: number | string) =>
    `Date should be greater or equal than ${value}`,
  submitFormError: "In order to proceed, please address highlighted fields",
  exceedsCharacterLimitation: (value: string | number) =>
    `Exceeds ${value} character limitation`,
  mustBeLessThanOrEqual: (value: string | number) =>
    `Must be less than or equal to ${value}`,
  mustBeGreaterThan: (value: string | number) =>
    `Must be greater than ${value}`,
  allowMaxDigitsAfterDecimalPoint: (value: string | number) =>
    `Value should have maximum ${value} digits after decimal point`,
  name: "Name",
  state: "State",
  firstName: "First Name",
  lastName: "Last name",
  mobilePhone: "Mobile Phone",
  addNew: "Add New",
  createNew: "Create New",
  employers: "Employers",
  employees: "Employees",
  trackingEntities: "Tracking Entities",
  trackingEntity: "Tracking Entity",
  employeeInformation: "Employee Information",
  addAnother: "Add Another",
  done: "Done",
  created: "Created",
  updated: "Updated",
  update: "Update",
  employer: "Employer",
  employerId: "Employer External ID",
  createEmployer: "Create Employer",
  viewModifyEmployer: "View / Modify Employer",
  employerDetails: "Employer Details",
  employerVerificationCode: "Employer verification code:",
  companyName: "Company Name",
  website: "Website",
  description: "Description",
  companyPhone: "Company Phone",
  streetAddress1: "Street Address 1",
  streetAddress2: "Street Address 2",
  city: "City",
  zip: "Zip",
  naics: "NAICS",
  numberEmployees: "Number of Employees",
  contactName: "Contact Name",
  contactEmail: "Contact Email",
  accountManager: "Account Manager",
  listBillEmail: "List Bill Email",
  status: "Status",
  substatus: "Substatus",
  startDate: "Start Date",
  openEnrollmentStartDate: "Open Enrollment Start Date",
  openEnrollmentEndDate: "Open Enrollment End Date",
  lastRenewalDate: "Last Renewal Date",
  nextRenewalDate: "Next Renewal Date",
  none: "None",
  agentOfRecord: "Agent of Record",
  addons: "Add-ons",
  iuaOptions: "IUA Options",
  productPairings: "Product Pairings",
  productType: "Product Type",
  referralAffiliate: "Referral Affiliate",
  internalSales: "Internal Sales",
  directPrimaryCar: "Direct Primary Care",
  leadGenerationAffiliate: "Lead Generation Affiliate",
  landingPageContent: "Landing Page Content",
  strategicAffiliate: "Strategic Affiliate",
  phone: "Phone",
  successfullyUpdated: "Successfully Updated",
  successfullyCreated: "Successfully Created",
  cannotCreate: "Cannot Create",
  product: "Product",
  referral: "Referral",
  notFound: "Not Found",
  trackingEntityDetails: "Tracking Entity Details",
  view: "View",
  url: "URL",
  copy: "Copy",
  copied: "Copied",
  monthlyContributionData: "Monthly Contribution Data",
  employerMonthlyContributionData: "Employer Monthly Contribution Data",
  period: "Period",
  export: "Export",
  monthlyContributionDetail: "Monthly Contribution Detail",
  monthlyContributionDetailDescription:
    "Statuses included in this dataset are Active, Pending Start Date & Pending Cancellation",
  monthlyContributionSummary: "Monthly Contribution Summary",
  monthlyContributionSummaryDescription:
    "Statuses included in this dataset are Active & Pending Start Date",
  cannotBeDecreased: "Cannot be decreased",
  membershipAgreementCannotBeLessThan: (months: number) =>
    `Membership Agreement term cannot be less than ${months} months`,
  lastRenewalDateCannotExceedLastRenewalDateAnniversary: (days: number) =>
    `Next Renewal date cannot exceed Original Next Renewal date + ${days} days`,
  discountBundles: "Discount Bundles",
  discountTiers: "Discount Tiers",
  members: "Members",
  bundleName: "Bundle Name",
  bundleType: "Bundle Type",
  defaultDisplayName: "Default Display Name",
  discount: "Discount",
  bundle: "Bundle",
  memberFriendlyName: "Member Friendly Name",
  listBill: "List Bill",
  noResultInList: "No Records Found",
  noFiltersSet: "No Filters Set",
  initialUnshareableAmount: "Initial Unshareable Amount",
  draft: "Draft",
  enableSkipToEnrollment: "Enable Skip To Enrollment",
};
