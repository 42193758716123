import { predefinedAnimations } from "../../animations";
import { colors } from "../../colors";

export const filledInputStyles = {
  root: {
    fontSize: "16px",
    transition: `all ${predefinedAnimations.default}`,

    "&:not(.Mui-error)": {
      background: colors.input.background,

      "&:focus, &:hover": {
        background: colors.input.backgroundHover,
      },
      "&:before, &$underline:before": {
        borderBottom: `1px solid ${colors.primary.main}`,
      },
    },
    "&.Mui-error": {
      background: colors.input.errorBackground,

      "&:focus, &:hover": {
        background: colors.input.errorBackgroundHover,
      },

      "& .MuiInputLabel-root.Mui-focused": {
        color: colors.error.main,
      },
    },
    "&$disabled": {
      pointerEvents: "none",
      // increase the specificity for the pseudo class
      background: colors.secondary.main,
    },

    "&.MuiFilledInput-underline.Mui-disabled": {
      "&:before": {
        borderBottomStyle: "solid",
      },
    },
  },
};
