import makeStyles from "@material-ui/styles/makeStyles";

import { CustomTheme } from "../../../theme/material/theme";
import { getFontError } from "../../../theme/utils/get-font-error";

const customCounter = "custom-counter";

export const useStyles = makeStyles((theme: CustomTheme) => ({
  ol: {
    paddingLeft: "21px",
    counterReset: customCounter,
    listStyle: "none",
    marginTop: "14px",
    marginBottom: 0,

    "p + &": {
      marginTop: (): string => {
        const fontError = getFontError("body1");
        const spacing = theme.spacing(3);

        return `calc(${spacing}px + ${-fontError.bottom}px)`;
      },
    },
    "& > li": {
      color: "currentColor",
      counterIncrement: customCounter,

      "&:last-child": {
        "& > p": {
          marginBottom: 0,
        },
      },

      "& > p": {
        paddingLeft: theme.spacing(2),
        textAlign: "left",
      },

      "&::before": {
        content: `counter(${customCounter})`,
        position: "absolute",
        background: theme.colors.background.darken,
        borderRadius: "50%",
        width: "20px",
        height: "20px",
        left: "-20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-end",
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: theme.typography.caption.fontSize,
        color: theme.palette.primary.main,
      },
    },
  },
}));
