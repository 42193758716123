import React, { ReactElement, ReactNode } from "react";

import { useStyles } from "./styles";

export const StyledOrderedList = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const { ol } = useStyles({});

  return <ol className={ol}>{children}</ol>;
};
