import { fade } from "@material-ui/core/styles";

import { colors } from "../../colors";
import { opacities } from "../../opacities";

export const tooltipStyles = {
  tooltip: {
    backgroundColor: fade(colors.primary.main, opacities.colored.secondary),
  },
};
