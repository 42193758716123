import { fade } from "@material-ui/core/styles";

import { colors } from "../colors";
import { opacities } from "../opacities";

export enum PaletteType {
  Primary = "primary",
  Secondary = "secondary",
  Success = "success",
  Error = "error",
  White = "white",
  Background = "background",
  Action = "action",
  Text = "text",
  Divider = "divider",
  Darken = "darken",
  Warning = "warning",
  Header = "Header",
}

export enum PaletteColors {
  Primary = PaletteType.Primary,
  Secondary = PaletteType.Secondary,
  Success = PaletteType.Success,
  Error = PaletteType.Error,
  Warning = PaletteType.Warning,
}

export const palette = {
  [PaletteType.Primary]: colors.primary,
  [PaletteType.Secondary]: colors.secondary,
  [PaletteType.Success]: colors.success,
  [PaletteType.Error]: colors.error,
  [PaletteType.Warning]: colors.warning,
  [PaletteType.White]: colors.white,
  [PaletteType.Background]: {
    default: "#fff",
  },
  [PaletteType.Action]: {
    active: fade("#000", opacities.basic.default),
    hover: colors.background.darken,
    selected: colors.background.darken,
    disabled: fade(colors.primary.main, opacities.colored.disabled),
  },
  [PaletteType.Text]: {
    secondary: fade(colors.primary.main, opacities.colored.secondary),
    disabled: fade(colors.primary.main, opacities.colored.disabled),
    hint: fade(colors.primary.main, opacities.colored.disabled),
  },
  [PaletteType.Divider]: colors.background.darken,
  [PaletteType.Header]: colors.background.header,
  [PaletteType.Darken]: {
    main: colors.background.darken,
    contrastText: colors.primary.main,
  },
};
