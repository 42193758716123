import { Variant } from "@material-ui/core/styles/createTypography";

import { typography } from "../material/typography";
import { theme } from "../material/theme";

export interface FontError {
  top: number;
  bottom: number;
}

export const getFontError = (variant = typography.fontVariant): FontError => {
  switch (variant) {
    case "subtitle1": {
      return { top: 3, bottom: 3 };
    }

    case "caption": {
      return { top: 3, bottom: 3 };
    }

    case "h5": {
      return { top: 8, bottom: 7 };
    }

    case "h6": {
      return { top: 4, bottom: 5 };
    }

    default: {
      return { top: 5, bottom: 4 };
    }
  }
};

export const calcMarginTop = (spacing: number, variant: Variant): string => {
  const sign = spacing > 0 ? -1 : 1;

  return `calc(${theme.spacing(spacing)}px + ${sign *
    getFontError(variant).top}px)`;
};
