import { initialAuthState } from "./state";
import { ActionType } from "./actions";
import { Action } from "./constants";
import { Auth } from "./types";

export const reducer = (state = initialAuthState, action: ActionType): Auth => {
  switch (action.type) {
    case Action.SetFullUserName:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};
