import ky, { Options, Input, HTTPError } from "ky";
import { stringify } from "qs";

import { wrapError, BaseError } from "./error-handler/errors";
import { RequestOptions } from "./types";
import { AuthToken } from "../features/auth/types";
import { getToken } from "../features/auth";
import { afterResponse } from "./middleware";
import { ErrorMessages } from "./error-handler/types";

export const defaultOptions: RequestOptions = {
  retry: {
    limit: 2,
  },
  hooks: {
    afterResponse: [afterResponse],
  },
  timeout: false,
};

const getRequestUrl = (url: Input, options: RequestOptions): string => {
  return `${url}${stringify(options?.query ?? {}, {
    addQueryPrefix: true,
  })}`;
};

class Api {
  _instance: typeof ky;

  constructor(options: Options) {
    this._instance = ky.create(options);
  }

  handleRequestError = async (
    error: HTTPError,
    options: RequestOptions,
  ): Promise<BaseError> => {
    const wrappedError = await wrapError(error);

    if (!options.skipNotification) {
      wrappedError.notify();
    }

    throw wrappedError;
  };

  async request<T>(url: Input, options: RequestOptions): Promise<T> {
    try {
      const response = this._instance(getRequestUrl(url, options), options);

      return await response.json<T>();
    } catch (error) {
      if (error instanceof SyntaxError) {
        if (error.message === ErrorMessages.UnexpectedEndOfJsonInput) {
          // Skip error if incorrect json in response
          return;
        }
      }

      await this.handleRequestError(error as HTTPError, options);
    }
  }

  get<T>(url: Input, options?: RequestOptions): Promise<T> {
    const requestOptions = { method: "GET", ...options };

    return this.request(url, requestOptions);
  }

  post<T>(url: Input, options?: RequestOptions): Promise<T> {
    const requestOptions = { method: "POST", ...options };

    return this.request(url, requestOptions);
  }

  put<T>(url: Input, options?: RequestOptions): Promise<T> {
    const requestOptions = { method: "PUT", ...options };

    return this.request(url, requestOptions);
  }

  patch<T>(url: Input, options?: RequestOptions): Promise<T> {
    const requestOptions = { method: "PATCH", ...options };

    return this.request(url, requestOptions);
  }

  delete<T>(url: Input, options?: RequestOptions): Promise<T> {
    const requestOptions = { method: "DELETE", ...options };

    return this.request(url, requestOptions);
  }

  setCustomHeader(header: string, value: string) {
    const opt: Options = {
      ...defaultOptions,
      hooks: {
        ...defaultOptions,
        beforeRequest: [
          (request) => {
            request.headers.set(header, value);
          },
        ],
      },
    };

    this._instance = this._instance.extend(opt);
  }
}

export const getApiClient = ({
  basePath,
  authToken,
}: {
  basePath: string;
  authToken?: AuthToken;
}): Api => {
  const options: Options = {
    ...defaultOptions,
    prefixUrl: basePath,
    hooks: authToken
      ? {
          ...defaultOptions.hooks,
          beforeRequest: [
            (request) => {
              request.headers.set(
                "Authorization",
                `Bearer ${getToken(authToken)}`,
              );
            },
          ],
        }
      : defaultOptions.hooks,
  };

  return new Api(options);
};
