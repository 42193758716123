import { makeStyles } from "@material-ui/core/styles";

import { CustomTheme } from "../../theme/material/theme";

const headerHeight = 64;
const sidebarWidth = 320;

export const useStyles = makeStyles((theme: CustomTheme) => ({
  wrapper: {
    height: "100vh",
  },
  grid: {
    height: "100%",
    display: "grid",
    gridTemplateColumns: `${sidebarWidth}px auto`,
    gridTemplateRows: `${headerHeight}px auto`,
    gridTemplateAreas: `
      "header header"
      "sidebar main"
    `,
  },
  header: {
    gridArea: "header",
  },
  column: {
    maxHeight: `calc(100vh - ${headerHeight}px)`,
    overflow: "auto",
  },
  sidebar: {
    gridArea: "sidebar",
  },
  main: {
    gridArea: "main",
  },
}));
