import React, { ReactElement } from "react";

import { AppContentProps } from "./types";
import { useStyles } from "./styles";

export const AppContent = ({ children }: AppContentProps): ReactElement => {
  const classes = useStyles({});

  return <div className={classes.root}>{children}</div>;
};

AppContent.displayName = "AppContent";
