export enum AuthToken {
  Access = "adminToken",
  Affiliate = "affiliateToken",
  Enrollment = "enrollmentToken",
  Product = "productToken",
  Member = "memberToken",
  EmployerToken = "employerToken",
  AcbToken = "acbToken",
}

export interface AdminAuthorizeDto {
  affiliateToken: string;
  productToken: string;
  memberToken: string;
  enrollmentToken: string;
  employerToken: string;
  acbToken: string;
}

//Value of enum should be equal to the VALUE field of the app role in app registration

export enum UserRole {
  AccessRefund = "ACCESS_REFUNDS",
  AccessRetrigger = "ACCESS_RETRIGGER",
  Adjustments = "ADJUSTMENTS",
  WriteOffs = "WRITEOFF",
  WriteOffContribution = "WRITEOFF_CONTRIBUTION",
}
