import React, { memo } from "react";
import { Box } from "@material-ui/core";

import { DecoratedTypography } from "ui/atoms/decorated-typography/decorated-typography.component";

import { PageWrapperProps } from "./types";

export const PageWrapper = memo(({ children, title }: PageWrapperProps) => {
  return (
    <React.Fragment>
      <Box marginTop={2}>
        <DecoratedTypography variant="h5" color="primary">
          {title}
        </DecoratedTypography>
      </Box>

      <Box marginTop={3}>{children}</Box>
    </React.Fragment>
  );
});

PageWrapper.displayName = "PageWrapper";
