import { VariantType } from "notistack";

import { EventMessage } from "./types";
import { snackEvent } from "./constants";

export const makeHandler = (variant: VariantType) => (
  message: string,
): void => {
  const event = new CustomEvent<EventMessage>(snackEvent, {
    detail: { message, variant },
  });

  window.dispatchEvent(event);
};
