import { useEffect, useCallback, useMemo, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import qs from "qs";
import { useDispatch } from "react-redux";

import { setFullUsernameAction } from "store/actions";
import { appConfig } from "app-config";
import { textLabels } from "core/constants/text-labels";

import { getToken, extractUsernameFromJWT, setToken, logout } from "./utils";
import { adminAuthorize } from "./shared/api/services-auth";
import { AuthToken } from "./types";
import { snack } from "../../lib/snack";

const accessToken = "#access_token";
const expiresIn = "expires_in";

export const useAuth = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [isLoadingTokens, setIsLoadingsTokens] = useState(false);
  const parsedQuery = qs.parse(location.hash, { ignoreQueryPrefix: true });
  const token = getToken();
  const affiliateToken = getToken(AuthToken.Affiliate);
  const enrollmentToken = getToken(AuthToken.Affiliate);
  const employerToken = getToken(AuthToken.EmployerToken);
  const productToken = getToken(AuthToken.Product);
  const memberToken = getToken(AuthToken.Member);
  const acbToken = getToken(AuthToken.AcbToken);
  const fullName = extractUsernameFromJWT(token);

  const adminAuthorizeRequest = useCallback(
    async (token, expires) => {
      try {
        setIsLoadingsTokens(true);
        setToken(AuthToken.Access, token, expires);

        const tokens = await adminAuthorize();

        setToken(AuthToken.Affiliate, tokens.affiliateToken, expires);
        setToken(AuthToken.Product, tokens.productToken, expires);
        setToken(AuthToken.Member, tokens.memberToken, expires);
        setToken(AuthToken.Enrollment, tokens.enrollmentToken, expires);
        setToken(AuthToken.EmployerToken, tokens.employerToken, expires);
        setToken(AuthToken.AcbToken, tokens.acbToken, expires);

        history.replace(appConfig.initialRoute);
        setIsLoadingsTokens(false);
      } catch {
        snack.error(textLabels.mainError);
        logout();
        history.replace(appConfig.initialRoute);
        setIsLoadingsTokens(false);
      }
    },
    [history],
  );

  useEffect(() => {
    if (fullName) {
      dispatch(setFullUsernameAction(fullName));
    }
  }, [dispatch, fullName]);

  useEffect(() => {
    if (!token && accessToken in parsedQuery) {
      adminAuthorizeRequest(parsedQuery[accessToken], parsedQuery[expiresIn]);
    }
  }, [parsedQuery, token, adminAuthorizeRequest]);

  return useMemo(
    () => ({
      token,
      affiliateToken,
      productToken,
      memberToken,
      isLoadingTokens,
      enrollmentToken,
      employerToken,
      acbToken,
    }),
    [
      token,
      affiliateToken,
      productToken,
      memberToken,
      enrollmentToken,
      employerToken,
      acbToken,
      isLoadingTokens,
    ],
  );
};
