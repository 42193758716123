import { ContentfulClientApi, createClient } from "contentful";

import { appConfig } from "app-config";

import { interceptorsConfig } from "./interceptors-config";

/**
 * Contentful Delivery API Client. Contains methods which allow access to any operations
 * that can be performed with a management token.
 *
 * Contains methods to fetch content, assets, etc.
 *
 * {@link ContentfulClientApi}
 */
export const getDeliveryApiClient = (): ContentfulClientApi => {
  if (!appConfig?.contentful?.deliveryAccessToken) {
    return;
  }

  return createClient({
    space: appConfig.contentful.space,
    accessToken: appConfig.contentful.deliveryAccessToken,
    environment: appConfig.contentful.environment,
    ...interceptorsConfig,
  });
};
