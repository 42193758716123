import { useCallback, useEffect } from "react";

import {
  BaseErrorPayload,
  ErrorEvents,
  ErrorListenerProps,
} from "api/error-handler/types";
import { snack } from "lib/snack";
import { useLogout } from "features/auth/use-logout";

export const useErrorListener = (props: ErrorListenerProps): void => {
  const { event, getMessage } = props;

  const eventHandler = useCallback(
    (data: { detail: BaseErrorPayload }): void =>
      snack.error(getMessage(data.detail)),
    [getMessage],
  );

  useEffect(() => {
    window.addEventListener(event, eventHandler as any);

    return (): void => window.removeEventListener(event, eventHandler as any);
  }, [event, eventHandler]);
};

export const useNetworkErrorListener = (): void => {
  const event = ErrorEvents.NetworkError;

  const getMessage = useCallback((): string => event, [event]);

  useErrorListener({ event, getMessage });
};

export const useUnexpectedErrorListener = (): void => {
  const event = ErrorEvents.UnexpectedError;

  const getMessage = useCallback((): string => event, [event]);

  useErrorListener({ event, getMessage });
};

export const useUnauthorizedErrorListener = (): void => {
  const logout = useLogout();
  const event = ErrorEvents.UnauthorizedError;

  const eventHandler = useCallback(async (): Promise<void> => {
    snack.error(event);

    logout();
  }, [event, logout]);

  useEffect(() => {
    window.addEventListener(event, eventHandler as any);

    return (): void => window.removeEventListener(event, eventHandler as any);
  }, [event, eventHandler]);
};

export const useInternalServerErrorListener = (): void => {
  const event = ErrorEvents.InternalServerError;

  const getMessage = useCallback((): string => event, [event]);

  useErrorListener({ event, getMessage });
};
