import React from "react";

import {
  useInternalServerErrorListener,
  useNetworkErrorListener,
  useUnauthorizedErrorListener,
  useUnexpectedErrorListener,
} from "./utils";

export const NetworkRequestErrorListener = (): React.ReactElement => {
  useInternalServerErrorListener();
  useNetworkErrorListener();
  useUnauthorizedErrorListener();
  useUnexpectedErrorListener();

  return null;
};
