import { appConfig } from "app-config";
import { getApiClient } from "api/client";
import { apiUrlsMap } from "api/urls";
import { AdminAuthorizeDto, AuthToken } from "features/auth/types";
import { MemberImpersonationDto } from "features/member/member-view-edit-formik-adapter/shared/models/member-impersonation-dto";

const apiClient = getApiClient({
  basePath: appConfig.authServiceUrl,
  authToken: AuthToken.Access,
});

export const adminAuthorize = async () => {
  return await apiClient.get<AdminAuthorizeDto>(
    apiUrlsMap.authService.admin.authorize.get(),
    {
      skipNotification: true,
    },
  );
};

export const getLoginOnBehalfTokenByMemberId = async (
  memberId: string,
): Promise<MemberImpersonationDto> => {
  return await apiClient.get<MemberImpersonationDto>(
    apiUrlsMap.authService.admin.getLoginOnBehalfToken.get(memberId),
  );
};
