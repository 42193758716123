export enum ErrorEvents {
  UnexpectedError = "UnexpectedError",
  NetworkError = "NetworkError",
  ValidationError = "ValidationError",
  UnauthorizedError = "UnauthorizedError",
  TooManyRequestsError = "TooManyRequestsError",
  InternalServerError = "InternalServerError",
  RequestTimeoutError = "RequestTimeoutError",
}

export enum ErrorMessages {
  UnexpectedEndOfJsonInput = "Unexpected end of JSON input",
}

export interface BaseErrorPayload {
  code: number;
  name: string;
}

export interface ErrorListenerProps {
  event: ErrorEvents;
  getMessage: (payload: BaseErrorPayload) => string;
}
