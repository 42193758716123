import { colors } from "../../colors";
import { predefinedAnimations } from "../../animations";

export const formLabelStyles = {
  root: {
    transition: `all ${predefinedAnimations.default}`,

    "&.Mui-error.Mui-focused": {
      color: colors.error.main,
    },
  },
};
