import { useEffect, useCallback, useRef } from "react";

import { appConfig } from "app-config";
import { timeoutEventName } from "core/constants/timeout";

import { useWatchUserActivity } from "./use-watch-user-activity";

export const useAdminTimeout = (): void => {
  const timeoutId = useRef(null);

  const setupTimeout = useCallback(() => {
    timeoutId.current = setTimeout(() => {
      const event = new CustomEvent(timeoutEventName);

      window.dispatchEvent(event);
    }, appConfig.adminSessionTimeout);
  }, []);

  useEffect(() => {
    setupTimeout();

    return (): void => {
      clearTimeout(timeoutId.current);
    };
  }, [setupTimeout]);

  const resetTimer = useCallback((): void => {
    clearTimeout(timeoutId.current);

    setupTimeout();
  }, [setupTimeout]);

  useWatchUserActivity(resetTimer);
};
