import { useSnackbar } from "notistack";
import React, { useCallback, useEffect } from "react";

import { Snack } from "ui/molecules/snack";

import { EventMessage } from "./types";
import { snackEvent } from "./constants";

export const useSnack = (): void => {
  const { enqueueSnackbar } = useSnackbar();

  const notificationHandler = useCallback(
    ({ detail: { message, variant } }: { detail: EventMessage }): void => {
      enqueueSnackbar("", {
        preventDuplicate: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
        // eslint-disable-next-line react/display-name
        content: (key): React.ReactElement => (
          <Snack key={key} message={message} variant={variant} />
        ),
      });
    },
    [enqueueSnackbar],
  );

  useEffect(() => {
    window.addEventListener(snackEvent, notificationHandler as any);

    return (): void => {
      window.removeEventListener(snackEvent, notificationHandler as any);
    };
  }, [notificationHandler]);
};
