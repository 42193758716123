import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core";
import { Provider } from "react-redux";
import { SWRConfig } from "swr";

import { AuthProvider } from "features/auth";

import { App } from "./App";
import * as serviceWorker from "./serviceWorker";
import { theme } from "./ui/theme/material/theme";
import { store } from "./store";

ReactDOM.render(
  <Router>
    <SWRConfig
      value={{
        revalidateOnFocus: false,
      }}
    >
      <Provider store={store}>
        <AuthProvider>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </AuthProvider>
      </Provider>
    </SWRConfig>
  </Router>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
