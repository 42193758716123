import { Entry, EntryCollection } from "contentful";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import isArray from "lodash/isArray";
import isObject from "lodash/isObject";
import keyBy from "lodash/keyBy";
import mapValues from "lodash/mapValues";
import groupBy from "lodash/groupBy";

import {
  Dictionary,
  IdentifiableContent,
  entryContentsPropertyName,
} from "lib/contentful";

/**
 * Recursively unwraps {@param entry} by {@param unwrapKey}
 *
 * Contentful contains a lot of metadata which in some cases can be redundant and
 * over complicates an usage, such as `fields`, `items` and `sys`. Therefore, method helps to unwrap
 * object value by specified key.
 *
 * If entry is a One To Many relation transform array to Dictionary { entryId: entryObject }, so
 * it's easier to get specific entry by id.
 *
 * Group {@see Content}
 *
 * @usageNotes
 * ###Example
 * Entry:
 * ```
 *  {
 *    sys: sysObject,
 *    fields: fieldsObject,
 *  }
 * ```
 * Using unwrapKey = 'fields', results:
 * ```
 *  {
 *    sys: sysObject,
 *    ...fieldsObject,
 *  }
 * ```
 *
 * @returns T
 * @param entry
 * @param unwrapKey
 */
export const unwrapEntry = <T extends IdentifiableContent>(
  unwrapKey: string,
  entry: Entry<T>,
): T => {
  // @ts-ignore
  const unwrappedEntry = Object.keys(entry[unwrapKey]).reduce(
    (accumulatorEntry, propertyName) => {
      const propertyValue = get(entry, [unwrapKey, propertyName]);

      const isEntry =
        // @ts-ignore
        isObject(propertyValue) && !isEmpty(propertyValue[unwrapKey]);

      let unwrappedPropertyValue: any;

      if (isEntry) {
        unwrappedPropertyValue = unwrapEntry(unwrapKey, propertyValue);
      } else if (isArray(propertyValue)) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define, no-use-before-define
        unwrappedPropertyValue = transformAndGroupEntriesArrayIntoDictionary(
          unwrapKey,
          propertyName,
          propertyValue,
        );
      } else {
        unwrappedPropertyValue = propertyValue;
      }

      return {
        ...accumulatorEntry,
        [propertyName]: unwrappedPropertyValue,
      };
    },
    {},
  );

  return unwrappedEntry as T;
};

/**
 * Transforms array of entries to dictionary: { entryId: entryObject }. So it's easier to get
 * specific entry by id.
 *
 * Groups {@see Content} from contentType
 *
 * @param unwrapKey
 * @param propertyName
 * @param propertyValue
 */
function transformAndGroupEntriesArrayIntoDictionary<
  T extends IdentifiableContent
>(
  unwrapKey: string,
  propertyName: string,
  propertyValue: Entry<T>[],
): Dictionary<T | Dictionary<T>> {
  const transformEntriesArrayIntoMapById = (
    entries: Entry<T>[],
  ): Dictionary<T> =>
    keyBy<T>(
      entries.map((entryItem) => unwrapEntry(unwrapKey, entryItem)),
      (entryItem: T) => entryItem.id,
    );

  if (propertyName === entryContentsPropertyName) {
    return mapValues(
      groupBy(propertyValue, (entryItem) => entryItem.sys.contentType.sys.id),
      transformEntriesArrayIntoMapById,
    );
  }

  return transformEntriesArrayIntoMapById(propertyValue);
}

/**
 * Unwraps `{@link EntryCollection}<T>` by getting rid of redundant metadata such as `fields`,
 * `items` and `sys`.
 *
 * {@see Entry} {@see Sys} {@see EntryCollection}
 *
 * @returns T[]
 * @param entryCollection
 */
export const unwrapEntryCollection = <T extends IdentifiableContent>(
  entryCollection: EntryCollection<T>,
): T[] => {
  return entryCollection.items.map((entry: Entry<T>) =>
    unwrapEntry<T>("fields", entry),
  );
};

export const entryIdentifierPath = "fields.id";
