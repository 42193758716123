import React, { ReactElement } from "react";
import { EntryFields } from "contentful";
import {
  documentToReactComponents,
  Options,
} from "@contentful/rich-text-react-renderer";
import { BLOCKS, Document, MARKS } from "@contentful/rich-text-types";

import { StyledListElement } from "ui/molecules/styled-list/styled-list-element";
import { StyledParagraph } from "ui/molecules/styled-paragraph";
import { StyledUnorderedList } from "ui/molecules/styled-list/styled-unordered-list";
import { StyledOrderedList } from "ui/molecules/styled-list/styled-ordered-list";
import { StyledSubtitle2 } from "ui/molecules/styled-subtitle2/styled-subtitle2.component";

/**
 * Rich Text render options to apply custom styling.
 *
 * Is used during serialization a Contentful Rich Text `document` to React tree
 *
 * @usageNotes
 * ###Example
 * `{documentToReactComponents(content, richTextRenderOptions)}`
 *
 * {@link documentToReactComponents}
 */
export const richTextRenderOptions: Options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_: any, children: any): ReactElement => {
      return <StyledParagraph>{children}</StyledParagraph>;
    },
    [BLOCKS.UL_LIST]: (_: any, children: any): ReactElement => {
      return <StyledUnorderedList>{children}</StyledUnorderedList>;
    },
    [BLOCKS.OL_LIST]: (_: any, children: any): ReactElement => {
      return <StyledOrderedList>{children}</StyledOrderedList>;
    },
    [BLOCKS.LIST_ITEM]: (_: any, children: any): ReactElement => {
      return <StyledListElement>{children}</StyledListElement>;
    },
    [BLOCKS.HEADING_5]: (_: any, children: any): ReactElement => {
      return <StyledSubtitle2>{children}</StyledSubtitle2>;
    },
  },
};

export const renderRichText = (
  richText: EntryFields.RichText,
  options: Options = richTextRenderOptions,
): ReturnType<typeof documentToReactComponents> =>
  documentToReactComponents(richText as Document, options);

export const renderRichTextWithComponent = (
  richText: EntryFields.RichText,
  component: ReactElement,
): ReturnType<typeof documentToReactComponents> => {
  return renderRichText(richText, {
    ...richTextRenderOptions,
    renderMark: {
      [MARKS.CODE]: (): ReactElement => {
        return component;
      },
    },
  });
};
