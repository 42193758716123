import { appConfig } from "app-config";
import { getManagementApiClient } from "lib/contentful";

export const getEnvironment = async () => {
  const managementClientApi = getManagementApiClient();

  const space = await managementClientApi.getSpace(appConfig.contentful.space);

  return await space.getEnvironment(appConfig.contentful.environment);
};
