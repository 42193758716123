import { colors } from "../../colors";
import { contentWidthConfig } from "../../layout";
import { ContentType } from "../../types";
import { Shadow, shadows } from "../shadows";
import { config } from "../../config";
import { predefinedAnimations } from "../../animations";

export const autocompleteStyles = {
  clearIndicator: {
    color: colors.primary.main,
  },
  popupIndicator: {
    color: colors.primary.main,
  },
  popper: {
    minWidth: contentWidthConfig[ContentType.Small],
  },
  listbox: {
    padding: "2px",
  },
  paper: {
    boxShadow: shadows[Shadow.Colored],
  },
  option: {
    borderRadius: config.borderRadius,
    height: 48,
    padding: "8px 16px",
    transition: `background-color ${predefinedAnimations.default}`,

    '&[aria-selected="true"]': {
      backgroundColor: colors.background.default,
    },

    "&:hover": {
      backgroundColor: colors.background.darken,
    },
  },
  root: {
    "& .MuiTextField-root": {
      backgroundColor: "transparent",

      "&:focus, &:hover": {
        background: "transparent",
      },

      "&:not(.Mui-error)": {
        backgroundColor: "transparent",
      },
    },
  },
};
