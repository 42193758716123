export interface Config {
  borderRadius: number;
  desktopHeaderHeight: number;
  mobileHeaderHeight: number;
  memberAccountHeaderHeight: number;
  iconBorderWidth: number;
  outlineElementBorderWidth: number;
}

export const config = {
  borderRadius: 8,
  desktopHeaderHeight: 72,
  mobileHeaderHeight: 56,
  memberAccountHeaderHeight: 64,
  iconBorderWidth: 2,
  outlineElementBorderWidth: 2,
  headerOpacity: 0.95,
};
