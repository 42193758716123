import { EntryFields, Asset as ContentfulAsset } from "contentful";

import { PaletteColors } from "ui/theme/material/palette";

/**
 * Represents Contenful Content Model scheme
 *
 * Contentful Scheme can be found here:
 * {@link https://app.contentful.com/spaces/{spaceId}/content_types}
 */

export interface IdentifiableContent {
  id: EntryFields.Text;
  contentfulDisplayTitle: EntryFields.Text;
}

export interface Dictionary<T> {
  [entryId: string]: T;
}

interface VisibleGroupingContent extends IdentifiableContent {
  title: EntryFields.Text;
  description: EntryFields.RichText;
}

interface ImageryContent extends IdentifiableContent {
  image?: Asset;
}

interface ColoredContent extends IdentifiableContent {
  color: Color;
}

export enum ContentModelType {
  Page = "page",
  Step = "step",
  Section = "section",
  Tooltip = "tooltip",
  Dialog = "dialog",
  Footer = "footer",
  Legal = "legal",
  Content = "content",
  Config = "config",
  SelectableCard = "selectableCard",
  Card = "card",
  Color = "color",
  CheckableContent = "checkableContent",
  TrackingEntity = "trackingEntity",
}

export enum TitleColor {
  DarkBlue = "DarkBlue",
  LightBlue = "LightBlue",
  White = "White",
}

export enum TitleAlignment {
  Left = "Left",
  Center = "Center",
  Right = "Right",
}

export interface Step extends IdentifiableContent {
  page: Page;
  children?: Dictionary<Page>;
}

export enum DomainType {
  Enrollment = "Enrollment",
  MemberPortal = "MemberPortal",
}

export const entryContentsPropertyName = "contents";

export interface Page extends IdentifiableContent, ImageryContent {
  domainType: DomainType;
  title: EntryFields.Text;
  description?: EntryFields.RichText;
  sections?: Dictionary<Section>;
  landingSections?: Dictionary<LandingSection>;
  tooltips?: Dictionary<Tooltip>;
  contents?: { [contentType in ContentModelType]: Dictionary<Content> };
  footer?: Footer;
  dialogs?: Dictionary<Dialog>;
}

export interface Section extends VisibleGroupingContent, ImageryContent {
  tooltips?: Dictionary<Tooltip>;
  contents?: Dictionary<Content>;
}

export interface LandingSection extends VisibleGroupingContent, ImageryContent {
  bgColor?: EntryFields.Text;
  titleColor: TitleColor;
  titleAlignment: TitleAlignment;
  // TODO: refactor, this field shouldn't belong to the LandingSection
  videoUrl?: EntryFields.Text;
  contents?: { [contentType in ContentModelType]: Dictionary<Content> };
}

export interface TrackingEntity extends IdentifiableContent {
  description: EntryFields.RichText;
  phone?: EntryFields.Text;
  email?: EntryFields.Text;
  bgColor?: EntryFields.Text;
  logo?: Asset;
  logoWhite?: Asset;
}

export interface Image extends IdentifiableContent {
  asset: Asset;
  spacing?: EntryFields.Object;
}

export interface Tooltip extends VisibleGroupingContent, ColoredContent {}
export interface Dialog extends VisibleGroupingContent, ColoredContent {}
export interface Footer extends VisibleGroupingContent {
  images?: Dictionary<Image>;
}

export interface RichContent extends IdentifiableContent {
  content: EntryFields.RichText;
}

export enum SelectableCardType {
  Addon = "Addon",
  TobaccoUse = "TobaccoUse",
  MembershipType = "MembershipType",
  BeneficiaryOption = "BeneficiaryOption",
  FundingSourceType = "FundingSourceType",
}

export enum SelectableCardSelectionType {
  Default = "default",
  Preselected = "preselected",
  HardSelected = "hardSelected",
}

export interface SelectableCard extends VisibleGroupingContent, ImageryContent {
  type: SelectableCardType;
  isPreselected: EntryFields.Boolean;
  selectionType: SelectableCardSelectionType;
  deselectDialog?: Dialog;
}

export enum CardType {
  Testimonial = "Testimonial",
  Benefit = "Benefit",
  Educational = "Educational",
  StepToEnroll = "StepToEnroll",
}

export interface Card extends VisibleGroupingContent, ImageryContent {
  type: CardType;
  bgColor?: EntryFields.Text;
}

export enum LegalDocumentType {
  DisclosureAndAccountAgreement = "DisclosureAndAccountAgreement",
  IdentificationAgreement = "IdentificationAgreement",
  MembershipPrinciple = "MembershipPrinciple",
}

export interface Legal extends VisibleGroupingContent {
  type: LegalDocumentType;
}

export interface Config extends IdentifiableContent {
  address?: EntryFields.Text;
  phone?: EntryFields.Text;
  logo?: Asset;
  invertedLogo?: Asset;
}

export interface Color {
  id: PaletteColors;
  contentfulDisplayTitle: EntryFields.Text;
}

export type CheckableContent = VisibleGroupingContent;
export type Content =
  | RichContent
  | Card
  | SelectableCard
  | Legal
  | CheckableContent;
export type Asset = ContentfulAsset["fields"];
