import { makeStyles } from "@material-ui/core/styles";

import { CustomTheme } from "ui/theme/material/theme";
import { Shadow, shadows } from "ui/theme/material/shadows";

import { contentWidthConfig } from "../../theme/layout";
import { ContentType } from "../../theme/types";

export const useStyles = makeStyles<CustomTheme>((theme) => {
  return {
    root: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      width: "100vw",
      backgroundColor: theme.colors.background.default,
    },
    content: {
      boxShadow: shadows[Shadow.Short],
      width: contentWidthConfig[ContentType.Small],
      padding: theme.spacing(5),
      borderRadius: theme.config.borderRadius,
      backgroundColor: theme.colors.white.main,
    },
  };
});
