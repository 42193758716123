import React, { createContext, useContext } from "react";

import { useAuth } from "./use-auth";

export const AuthCtx = createContext<{
  token: string;
  affiliateToken: string;
  productToken: string;
  isLoadingTokens: boolean;
}>({
  token: undefined,
  affiliateToken: undefined,
  productToken: undefined,
  isLoadingTokens: false,
});

export const AuthProvider = (props: { children: React.ReactNode }) => {
  const auth = useAuth();

  return <AuthCtx.Provider value={auth} {...props} />;
};

export const useAuthContext = () => {
  return useContext(AuthCtx);
};
